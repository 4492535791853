import React from "react";

import {
  Box as MUIBox,
  // Customizable Area Start
  withStyles,
  createStyles,
  Button,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import BannerWeb from "../../../components/src/Banner.web";
import WebRoutesGenerator from "../../../components/src/NativeWebRouteWrapper";
import { routeMapUsageStats } from "../../../web/src/App";
// Customizable Area End

import UsageStatsMainController, {
  Props,
} from "./UsageStatsMainController.web";

class UsageStatsMain extends UsageStatsMainController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <MUIBox>
        <BannerWeb title="Usage Stats" />
        <MUIBox className={this.props.classes.buttons}>
          {this.buttons.map((button, index) => {
            return (
              <Button
                key={button.name}
                data-test-id="usage-stats-btns"
                className={this.determineIfActiveLink(`${button.link}`)}
                onClick={() => this.navigateTo(index)}
              >
                {button.name}
              </Button>
            );
          })}
        </MUIBox>
        <MUIBox minHeight={"90vh"} padding="2em">
          {WebRoutesGenerator({ routeMap: routeMapUsageStats })}
        </MUIBox>
      </MUIBox>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const basicStyles = {
  buttons: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "1em",
    marginTop: "2em",
    "& button": {
      color: "#FF5C5C",
      border: "1px solid #FF5C5C",
      padding: "0.5em 1em",
    },
  },
  active: {
    color: "white!important",
    padding: "0.5em 1em",
    background: "#FF5C5C",
  },
};

export const webStyleUsageStatsMain = () =>
  createStyles({
    ...basicStyles,
  });
export default withStyles(webStyleUsageStatsMain)(UsageStatsMain);
export { UsageStatsMain };
// Customizable Area End
