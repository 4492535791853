import React from "react";

import {
  Box,
  // Customizable Area Start
  withStyles,
  createStyles,
  Typography,
  Button,
  Grid,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import Footer from "../../../components/src/Footer.web";
import { myranklogo, bright } from "./assets";
import ReactPlayer from "react-player";
import Loader from "../../../components/src/Loader.web";
// Customizable Area End

import GuestLiveClassController, {
  AdvantagesType,
  LecturesType,
  Props,
  configJSON,
} from "./GuestLiveClassController.web";

class GuestLiveClass extends GuestLiveClassController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  ItemDisplay = ({ image, text }: AdvantagesType) => {
    return (
      <Box className={this.props.classes.containerAdvantage}>
        <Box className={this.props.classes.outerCircle}>
          <Box className={this.props.classes.innerCircle}>
            <img src={image ? image : bright} alt={"image-item"} />
          </Box>
        </Box>
        <Typography>{this.limitText(text)}</Typography>
      </Box>
    );
  };

  LectureDisplay = ({ attributes, id }: LecturesType) => {
    return (
      <Box
        key={`${id}-lectures`}
        className={this.props.classes.containerLecture}
      >
        <Box className={this.props.classes.videoBox}>
          <ReactPlayer key={`${id}-lectures`} url={attributes.video} controls />
        </Box>
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignContent={"center"}
          width="50%"
        >
          <Typography>Sample Video Lecture {id}</Typography>
        </Box>
      </Box>
    );
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box>
        <Loader loading={this.state.loading} />
        <Box className={this.props.classes.liveClassContainer}>
          <Typography variant="h5">
            {this.state.isRecorded ? "Recorded Classes" : "Live Classes"}
          </Typography>
          <Box className={this.props.classes.containerMainBox}>
            <Typography variant="h5" style={webStyle.headerStyle}>
              Advantages
            </Typography>
            <Grid
              container
              spacing={10}
              className={this.props.classes.containerGrid}
            >
              {this.state.advantages.map((data, index) => {
                return (
                  <Grid
                    item
                    key={`${data.id}-advantages-${index}`}
                    xs={12}
                    sm={6}
                    md={4}
                    className={this.props.classes.gridStyleBox}
                  >
                    <this.ItemDisplay {...data} />
                  </Grid>
                );
              })}
            </Grid>
            <Button data-test-id="grab-pack" onClick={this.navigateToRegister}>
              Grab this pack
            </Button>
            <Box className={this.props.classes.lecturesContainer}>
              <Typography variant="h5" style={webStyle.headerStyle2}>
                {configJSON.lectureTitle}
              </Typography>
              <Box
                width={"100%"}
                display="flex"
                flexDirection={"column"}
                gridGap="3em"
              >
                {this.state.lectures.map((lecture) => {
                  return <this.LectureDisplay {...lecture} />
                })}
              </Box>
            </Box>
            <Button data-test-id="grab-pack" onClick={this.navigateToRegister}>
              Grab this pack
            </Button>
          </Box>
        </Box>
        <Box data-test-id="footer" className={this.props.classes.footerLanding}>
          <Box className="footer-section">
            <Box>
              <img src={myranklogo} alt="logomainfooter" />
              <Typography
                variant="body1"
                className={this.props.classes.logoDesc}
              >
                Top learning experiences that create more talent in the world.
              </Typography>
            </Box>
            <Box>
              <Typography>Product</Typography>
              <ul>
                <li onClick={this.navigateToAYQ} data-test-id="to-ayq">
                  AYQ
                </li>
                <li>FAQ</li>
                <li>Pricing</li>
                <li>Articles</li>
              </ul>
            </Box>
            <Box>
              <Typography>Company</Typography>
              <ul>
                <li>About Us</li>
                <li>Notifications</li>
                <li>Login</li>
                <li>Blog</li>
              </ul>
            </Box>
            <Box>
              <Typography>Social</Typography>
              <ul>
                <li>Twitter</li>
                <li>LinkedIn</li>
                <li>Instagram</li>
                <li>Facebook</li>
              </ul>
            </Box>
            <Box>
              <Typography>Legal</Typography>
              <ul>
                <li>Terms</li>
                <li>Privacy</li>
                <li>Cookies</li>
                <li>Contact</li>
              </ul>
            </Box>
          </Box>
        </Box>
        <Footer backgroundColor="#170E29" />
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  headerStyle: {
    fontWeight: 500,
    fontSize: "1.7em",
  },
  headerStyle2: {
    fontWeight: 500,
    fontSize: "1.7em",
    marginBottom: "3em",
  },
};

export const webStyleGuestLiveClass = () =>
  createStyles({
    videoBox: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      background: "#ddd",
      padding: "3em",
    },
    liveClassContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      background: "#520082",
      minHeight: "95vh",
      "& > h5": {
        color: "white",
        marginTop: "2em",
      },
      "& button": {
        color: "white",
        width: "400px",
        height: "60px",
        fontWeight: 500,
        fontSize: "1.4em",
        margin: "2.5em 0",
        background:
          "linear-gradient(90deg,rgba(255, 63, 89, 1) 0%,rgba(254, 98, 86, 1) 100%)",
      },
    },
    containerMainBox: {
      width: "90%",
      height: "max-content",
      background: "white",
      padding: "2em",
      margin: "3em 0 7em 0",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    containerAdvantage: {
      maxWidth: "200px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      "& p": {
        fontWeight: 500,
        fontSize: "1.5em",
        marginTop: "1em",
      },
    },
    outerCircle: {
      width: "170px",
      height: "170px",
      borderRadius: "50%",
      background: "#E8DDEE",
      position: "relative",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    innerCircle: {
      width: "150px",
      height: "150px",
      borderRadius: "50%",
      background: "#D4C0DF",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "& img": {
        maxWidth: "90px",
      },
    },
    gridStyleBox: {
      display: "flex",
      justifyContent: "center",
    },
    containerGrid: {
      marginTop: "3em",
    },
    lecturesContainer: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
    },
    containerLecture: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      alignItems: "center",
      padding: "0 10em",
      "&:nth-child(even)": {
        flexDirection: "row-reverse",
      },
      "& p": {
        fontWeight: 500,
        fontSize: "1.8em",
        color: "#520082",
      },
    },
    footerLanding: {
      backgroundColor: "#170E29",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      paddingTop: "5em",
      color: "white",
      height: "350px",

      "& .footer-section": {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-around",
        alignContent: "center",

        "& ul": {
          height: "150px",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "center",
          gap: "1em",
        },

        "& img": {
          width: "200px",
          marginBottom: "2em",
        },

        "& li": {
          listStyleType: "none",
          cursor: "pointer",
        },

        "& h4": {
          color: "#98a2b3",
        },
      },

      "& .footer-bottom": {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0 2em",
        height: "10%",

        "& div": {
          display: "flex",
          gap: "1.5em",
        },
      },
    },
    logoDesc: {
      width: "70%",
    },
  });
export default withStyles(webStyleGuestLiveClass)(GuestLiveClass);
export { GuestLiveClass };
// Customizable Area End
