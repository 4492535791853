import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import storage from "../../../framework/src/StorageProvider.web";
import { WithStyles } from "@material-ui/core/styles";
import { ValueType } from "react-select";

export interface RecordedClass {
  id: string;
  type: string;
  attributes: Attributes;
}

export interface Attributes {
  id: number;
  subject_name: string | null;
  chapter_name: string;
  recording_url: string;
  logo: string;
  class_status: string;
  conducted_by: string;
  academic_year: string;
  view_recorded_classes_count: number;
  average_rating: number;
  created_at: string;
  updated_at: string;
}

type OptionType = {
  subjects: Array<{ value: string; label: string }>;
  chapters: Array<{ value: string; label: string }>;
};
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  recordedClasses: RecordedClass[];
  subjectButtons: string[];
  activeSubject: number;
  currentSubjectTable: RecordedClass[];
  userInput: {
    chapterSort: string;
  };
  search: string;
  isModalOpenFilter: boolean;
  isModalOpenSort: boolean;
  options: OptionType;
  userInputCurrent: {
    currentSubject: string;
    currentChapter: string;
    currentSortChapter: string;
  };
  loading: boolean;
  currentPageTable: number;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class RecordedClassController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getRecordedClassMessageId = "";
  private searchTimeout: NodeJS.Timeout | null = null;
  breadcrumb = [
    {
      label: "Recorded Classes",
      link: this.props.navigation.history.location.pathname,
    },
  ];
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      recordedClasses: [],
      currentSubjectTable: [],
      subjectButtons: [],
      options: {
        chapters: [],
        subjects: [],
      },
      userInputCurrent: {
        currentSubject: "",
        currentChapter: "",
        currentSortChapter: "",
      },
      activeSubject: 0,
      userInput: {
        chapterSort: "",
      },
      search: "",
      isModalOpenFilter: false,
      isModalOpenSort: false,
      loading: false,
      currentPageTable: 1,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getRecordedClassMessageId) {
      if (response?.data) {
        this.handleSuccessfulFetchRecords(response.data);
      }
    }
    // Customizable Area End
  }

  // web events
  componentDidMount = async () => {
    this.getRecordedClass();
    const propPassingRC = new Message(
      getName(MessageEnum.NavigationBreadcrumbMessage)
    );
    propPassingRC.addData(
      getName(MessageEnum.BreadcrumbDataMessage),
      this.breadcrumb
    );
    this.send(propPassingRC);
  };

  handleSuccessfulFetchRecords = (response: RecordedClass[]) => {
    const recordMap: { [record: string]: number } = {};
    response.forEach((recordData) => {
      if (recordData.attributes.subject_name === null) {
        recordMap["Unnamed Subject"] = 1;
      } else {
        recordMap[recordData.attributes.subject_name] = 1;
      }
    });

    const options: OptionType = {
      subjects: [],
      chapters: [],
    };

    response.forEach((data) => {
      const { subject_name, chapter_name } = data.attributes;
      if (
        subject_name &&
        !options.subjects.some((opt) => opt.value === subject_name)
      ) {
        options.subjects.push({ value: subject_name, label: subject_name });
      }
      if (
        chapter_name &&
        !options.chapters.some((opt) => opt.value === chapter_name)
      ) {
        options.chapters.push({ value: chapter_name, label: chapter_name });
      }
    });

    this.setState(
      {
        recordedClasses: response,
        subjectButtons: Object.keys(recordMap),
        options,
        loading: false,
        currentPageTable: 1,
      },
      () => {
        this.handleCurrentSubject(this.state.recordedClasses);
      }
    );
  };

  handleUserInputChangeCurrent = (
    event: ValueType<
      {
        value: string;
        label: string;
      },
      false
    >,
    type: "currentSubject" | "currentChapter" | "currentSortChapter"
  ) => {
    this.setState({
      userInputCurrent: {
        ...this.state.userInputCurrent,
        [type]: event?.value,
      },
    });
  };

  handleActiveSubject = (subject: string) => {
    const index = this.state.subjectButtons.indexOf(subject);
    this.setState(
      {
        activeSubject: index,
        currentPageTable: 1,
      },
      () => {
        this.handleCurrentSubject(this.state.recordedClasses);
      }
    );
  };

  handleSearch = (search: string) => {
    if (this.searchTimeout) {
      clearTimeout(this.searchTimeout);
    }
    if (search.trim() !== "") {
      this.setState(
        {
          search,
          currentPageTable: 1,
        },
        () => {
          this.searchTimeout = setTimeout(() => {
            this.performSearch(search);
          }, 600);
        }
      );
    } else {
      this.setState(
        {
          search: "",
          currentPageTable: 1,
        },
        () => {
          this.handleActiveSubject(
            this.state.subjectButtons[this.state.activeSubject]
          );
        }
      );
    }
  };

  handleCurrentSubject = (response: RecordedClass[]) => {
    const currentSubjectTable = response.filter((subject) => {
      if (
        this.state.subjectButtons[this.state.activeSubject] ===
          "Unnamed Subject" &&
        subject.attributes.subject_name === null
      ) {
        return true;
      } else {
        return (
          subject.attributes.subject_name ===
          this.state.subjectButtons[this.state.activeSubject]
        );
      }
    });

    this.setState({
      currentSubjectTable,
      currentPageTable: 1,
    });
  };

  onKeyEnterPressSearch = (eventKey: string) => {
    if (eventKey === "Enter") {
      this.performSearch(this.state.search);
    }
  };

  handleCloseModalFilter = () => {
    this.setState({
      isModalOpenFilter: false,
    });
  };

  handleOpenModalFilter = () => {
    this.setState({
      isModalOpenFilter: true,
    });
  };

  handleCloseModalSort = () => {
    this.setState({
      isModalOpenSort: false,
    });
  };

  handleOpenModalSort = () => {
    this.setState({
      isModalOpenSort: true,
    });
  };

  doneSort = () => {
    this.performSort(this.state.userInputCurrent.currentSortChapter);
    this.setState({
      userInputCurrent: {
        ...this.state.userInputCurrent,
        currentSortChapter: "",
      },
      currentPageTable: 1,
    });
  };

  performSort = async (chapterName: string) => {
    const searched = this.state.recordedClasses.filter((subject) => {
      const topic = subject.attributes.chapter_name
        .toLowerCase()
        .includes(chapterName.toLowerCase());
      if (topic) {
        return subject;
      }
    });

    this.setState({
      currentSubjectTable: searched,
      isModalOpenSort: false,
      currentPageTable: 1,
    });
  };

  doneFilter = () => {
    this.performFilter(
      this.state.userInputCurrent.currentSubject,
      this.state.userInputCurrent.currentChapter
    );
    this.setState({
      userInputCurrent: {
        ...this.state.userInput,
        currentSubject: "",
        currentChapter: "",
        currentSortChapter: "",
      },
      currentPageTable: 1,
    });
  };

  performFilter = async (subjectName: string, chapterName: string) => {
    const searched = this.state.recordedClasses.filter((subject) => {
      const subjectNameSearch = subject.attributes.subject_name
        ?.toLowerCase()
        ?.includes(subjectName.toLowerCase());
      const topic = subject.attributes.chapter_name
        .toLowerCase()
        .includes(chapterName.toLowerCase());
      if (subjectNameSearch && topic) {
        return subject;
      }
    });

    let currentSubject = this.state.activeSubject;

    if (searched.length > 0) {
      let found = false;

      for (const searchedItem of searched) {
        for (const [_, subjectButton] of this.state.subjectButtons.entries()) {
          if (searchedItem.attributes.subject_name === subjectButton) {
            found = true;
            break;
          }
        }
        if (found) break;
      }
    }

    this.setState({
      currentSubjectTable: searched,
      isModalOpenFilter: false,
      activeSubject: currentSubject,
      currentPageTable: 1,
    });
  };

  // Customizable Area Start
  getRecordedClass = async () => {
    this.setState({
      loading: true,
    });
    const getRecordedClassMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const headers = {
      token: await storage.get("authToken"),
    };

    this.getRecordedClassMessageId = getRecordedClassMessage.messageId;

    getRecordedClassMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    getRecordedClassMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_scheduling/recorded_classes`
    );

    getRecordedClassMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    runEngine.sendMessage(getRecordedClassMessage.id, getRecordedClassMessage);
  };

  performSearch = async (search: string) => {
    search = search.toLowerCase();
    const searched = this.state.recordedClasses.filter((subject) => {
      const subjectName = subject.attributes.subject_name
        ?.toLowerCase()
        ?.includes(search);
      const topic = subject.attributes.chapter_name
        .toLowerCase()
        .includes(search);
      const date = subject.attributes?.academic_year
        ?.toLowerCase()
        ?.includes(search);
      if (subjectName || topic || date) {
        return subject;
      }
    });

    this.setState({
      currentSubjectTable: searched,
      currentPageTable: 1,
    });
  };

  handlePageChangeTable = (value: number) => {
    this.setState({
      currentPageTable: value,
    });
  };
  // Customizable Area End
}
