import React from "react";

import {
  Box,
  // Customizable Area Start
  withStyles,
  createStyles,
  Modal,
  Typography,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import WebRoutesGenerator from "../../../components/src/NativeWebRouteWrapper";
import { routeMapDashboard } from "../../../web/src/App";
import { book, home, myranklogo, document, notes } from "./assets";
import Footer from "../../../components/src/Footer.web";
import { Sidebar, Menu, MenuItem, sidebarClasses } from "react-pro-sidebar";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Button from "../../../components/src/Button.web";
import MenuIcon from "@material-ui/icons/Menu";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
// Customizable Area End

import DashboardGuidanceController, {
  Props,
  configJSON,
} from "./DashboardGuidanceController.web";

class DashboardGuidance extends DashboardGuidanceController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box data-test-id="guidance-dashboard">
        <Box
          className={this.props.classes.dashboardHeader}
          component={"header"}
          display="flex"
          justifyContent={"space-between"}
          alignItems={"center"}
          boxShadow={"1px 1px 1px gray"}
          padding="0 3em"
        >
          <Box className="header-nav">
            <img
              src={myranklogo}
              alt="logomyrank"
              height={"39px"}
              data-test-id="navigate-to-landing-page"
              onClick={() => this.navigateToLandingPage()}
            />
            <MenuIcon
              data-test-id="sidebar-btn"
              className="menu-icon"
              onClick={() => this.setShowSidebar()}
            />
          </Box>
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            gridGap={"5em"}
            position={"relative"}
          >
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              style={{ cursor: "pointer" }}
              data-test-id="toggle-profile-popup"
              onClick={() => this.toggleProfilePopUp()}
            >
              {this.state.userData?.attributes.image ? (
                <img
                  src={this.state.userData.attributes.image}
                  style={{ borderRadius: "50%", width: "30px" }}
                />
              ) : (
                <AccountCircleIcon className={this.props.classes.accountImg} />
              )}
              <Typography variant="h6">
                {this.state.userData?.attributes.name}
              </Typography>
              <span style={{ position: "relative", left: "-5px" }}>
                <ArrowDropDownIcon style={{ color: "#949497" }} />
              </span>
            </Box>
            <Box
              className={
                this.state.profilePopup
                  ? this.props.classes.profilePopUp
                  : this.props.classes.profilePopUpHidden
              }
            >
              <Box
                className={this.props.classes.profilePopUpTypo}
                height={"50%"}
                data-test-id="navigate-to-packages"
                onClick={() => {
                  this.navigateToPackages();
                  this.toggleProfilePopUp();
                }}
              >
                <Typography>My Registration</Typography>
              </Box>
              <Box
                className={this.props.classes.profilePopUpTypo}
                height={"50%"}
                onClick={this.logout}
              >
                <Typography>Sign Out</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box className={this.props.classes.sideBarContainer}>
          <Box position={"relative"}>
            <Sidebar
              className={this.props.classes.dashboardSidebar}
              rootStyles={{
                [`.${sidebarClasses.container}`]: {
                  backgroundColor: "inherit",
                  position: "relative",
                  minHeight: "97vh",
                },
              }}
              collapsed={!this.state.showSidebar}
              collapsedWidth="0"
              data-test-id="sidebar-container"
            >
              <Menu
                rootStyles={{
                  paddingBottom: "15em",
                  ".ps-menu-button:hover": {
                    background: "inherit",
                  },
                }}
              >
                <MenuItem
                  icon={<img src={home} className="nav-icon" />}
                  onClick={() => this.navigateToHome()}
                  className={this.determineIfActiveLink(
                    "/dashboard-guidance/home"
                  )}
                  data-test-id="nav-btns"
                >
                  {configJSON.home}
                </MenuItem>
                <MenuItem
                  icon={<img src={book} className="nav-icon" />}
                  onClick={() => this.navigateToCourseFinder()}
                  className={this.determineIfActiveLink(
                    "/dashboard-guidance/course-finder"
                  )}
                  data-test-id="nav-btns"
                >
                  {configJSON.courseFinder}
                </MenuItem>
                <MenuItem
                  icon={<img src={notes} className="nav-icon" />}
                  onClick={() => this.navigateToCutOff()}
                  className={this.determineIfActiveLink(
                    "/dashboard-guidance/cut-off-analysis"
                  )}
                  data-test-id="nav-btns"
                >
                  {configJSON.cutoff}
                </MenuItem>
                <MenuItem
                  icon={<img src={document} className="nav-icon" alt="Icon" />}
                  onClick={() => this.navigateToApplications()}
                  className={this.determineIfActiveLink(
                    "/dashboard-guidance/applications"
                  )}
                  data-test-id="nav-btns"
                >
                  {configJSON.applications}
                </MenuItem>
                <MenuItem
                  icon={<img src={document} className="nav-icon" alt="Icon" />}
                  onClick={() => this.navigateToDocuments()}
                  className={this.determineIfActiveLink(
                    "/dashboard-guidance/documents-upload"
                  )}
                  data-test-id="nav-btns"
                >
                  Documents
                </MenuItem>
                <MenuItem
                  icon={<img src={document} className="nav-icon" alt="Icon" />}
                  onClick={() => this.navigateUsageStats()}
                  className={this.determineIfActiveLink(
                    "/dashboard-guidance/usage-stats"
                  )}
                  data-test-id="nav-btns"
                >
                  Usage Stats
                </MenuItem>
              </Menu>
              <Menu
                rootStyles={{
                  ".ps-menu-button:hover": {
                    background: "inherit",
                  },
                }}
                className={this.props.classes.logoutStyle}
              >
                <MenuItem
                  icon={<ExitToAppIcon className="nav-icon" />}
                  onClick={() => this.logout()}
                  data-test-id="logout-btn"
                >
                  Logout
                </MenuItem>
              </Menu>
            </Sidebar>
          </Box>
          <Box className={this.props.classes.content}>
            {WebRoutesGenerator({ routeMap: routeMapDashboard })}
          </Box>
        </Box>
        <Footer />
        <Modal open={this.state.isModalOpenLogout} onClose={this.handleModal}>
          <Box className={this.props.classes.modalStyle}>
            <p>You Successfully Logged Out</p>
            <Box
              onClick={() => this.navigateToLandingPage()}
              data-test-id="logout-btn-modal"
            >
              <Button
                key={"buttonKey"}
                width="200px"
                title="Go Back To Landing Page"
                color="white"
                height="50px"
              />
            </Box>
          </Box>
        </Modal>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyleDashboardGuidance = () =>
  createStyles({
    sideBarContentContainer: {
      display: "flex",
      width: "100%",
      marginTop: "1em",
      "& .sidebar-main-container": {
        "& #sideBarClose": {
          width: "0",
          left: "-600px",
        },
      },
    },
    content: {
      width: "100%",
      minHeight: "110vh",
      height: "max-content",
    },
    burgerButton: {
      position: "absolute",
      top: "10px",
      right: "-25px",
      cursor: "pointer",
      zIndex: 9999,
    },
    sideBarContainer: {
      display: "flex",
      marginBottom: "5em",
    },
    dashboardSidebar: {
      minHeight: "97vh",
      height: "100%",
      backgroundColor: "#520082",
      color: "white",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      borderBottomRightRadius: "2em",
      padding: "2em 0",
      transition: "all 0.3s ease",
      position: "relative",
      "& *": {
        whiteSpace: "wrap",
      },
      "& .nav-icon": {
        width: "20px",
        height: "20px",
        color: "#a77ec0",
      },
      "& .active-link": {
        color: "white",
        fontWeight: "bold",
      },
    },
    logoutStyle: {
      position: "absolute",
      bottom: "25px",
    },
    modalStyle: {
      left: "50%",
      alignItems: "center",
      justifyContent: "center",
      transform: "translate(-50%, -50%)",
      gap: "0.5em",
      top: "50%",
      position: "absolute" as "absolute",
      flexDirection: "column",
      height: "210px",
      border: "none",
      width: "calc(max-content + 100px)",
      padding: "2em",
      display: "flex",
      borderRadius: "0.5em",
      backgroundColor: "white",
      "& h6": {
        marginBottom: "1em",
      },
    },
    dashboardHeader: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      height: "71px",
      width: "100%",

      "& .header-nav": {
        display: "flex",
        alignItems: "center",
        gap: "1em",

        "& h1": {
          fontSize: "2rem",
          fontWeight: "bold",
        },

        "& img": {
          cursor: "pointer",
        },

        "& .menu-icon": {
          display: "inline-block",
          width: "100px",
          fontSize: "2em",
          cursor: "pointer",
        },
      },
    },
    profilePopUp: {
      position: "absolute" as const,
      display: "flex",
      flexDirection: "column" as const,
      height: "100px",
      width: "200px",
      top: "60px",
      textAlign: "center" as const,
      color: "black",
      right: "0px",
      border: "1px solid gray",
      borderRadius: "0.7em",
      background: "white",
      zIndex: 9999,
    },
    profilePopUpTypo: {
      "&:hover": {
        background: "rgb(81,0,129)",
        borderRadius: "0.35em",
        color: "white",
      },
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      cursor: "pointer",
    },
    profilePopUpHidden: {
      display: "none",
    },
  });
export default withStyles(webStyleDashboardGuidance)(DashboardGuidance);
export { DashboardGuidance };
// Customizable Area End
