import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import storage from "../../../framework/src/StorageProvider.web";
import { WithStyles } from "@material-ui/core";
import { SubjectData } from "./SynopsisSubjectsController.web";
interface TopicData {
  id: string;
  type: string;
  attributes: {
    id: number;
    name: string;
    created_at: string;
    updated_at: string;
    remarks: string;
    remark_2: string;
    date_synopsis: string | null;
    question_status: ActivityStatus;
    synopsis: string | null;
    topic_read: boolean;
    saved_notes_count: number;
    is_bookmarked: boolean;
  };
}

type ActivityStatus = "inactive" | "active";
// Customizable Area End

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  topic: TopicData | null;
  topicsData: TopicData[];
  highlights: string;
  modalBookmark: boolean;
  notesModal: boolean;
  reportModal: boolean;
  rateModal: boolean;
  rateNoteModal: boolean;
  starsIndex: number;
  rateText: string;
  reportText: string;
  rateSuccessModal: boolean;
  reportSuccessModal: boolean;
  loading: boolean;
  currentPage: number;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class SynopsisTopicReadController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getTopicReadSynopsisMessageId = "";
  getTopicsMessageId = "";
  breadcrumb = [
    {
      link: `/dashboard/synopsis`,
      label: "Synopsis",
    },
    {
      link: `/dashboard/synopsis/chapters/${this.props.navigation.getParam(
        "subjectId"
      )}`,
      label: "Chapters",
    },
    {
      link: `/dashboard/synopsis/chapters/${this.props.navigation.getParam(
        "subjectId"
      )}/topics/${this.props.navigation.getParam("chapterId")}`,
      label: "Topics",
    },
    {
      link: this.props.navigation.history.location.pathname,
      label: "Topics Read",
    },
  ];
  fetchBookmarkMessageId = "";
  saveBookmarMessageId = "";
  rateTopicMessageId = "";
  reportTopicMessageId = "";
  getSubjectNameMessageId = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      topic: null,
      topicsData: [],
      highlights: "",
      modalBookmark: false,
      notesModal: false,
      rateModal: false,
      rateNoteModal: false,
      reportModal: false,
      starsIndex: -1,
      rateText: "",
      reportText: "",
      rateSuccessModal: false,
      reportSuccessModal: false,
      loading: false,
      currentPage: 1,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    switch (apiRequestCallId) {
      case this.getTopicReadSynopsisMessageId:
        if (response.data) {
          this.handleTopicReadData(response.data);
        }
        break;
      case this.getTopicsMessageId:
        if (response.data) {
          this.handleTopicReadData2(response.data, response.meta.chapter.name);
          this.handleBookmark(response.data[0]);
        }
        break;
      case this.saveBookmarMessageId:
        if (response.data) {
          this.handleBookmarkModal();
        }
        break;
      case this.rateTopicMessageId:
        if (response.message) {
          this.handleRateSuccessModal();
          this.handleStarsNoteModal();
        }
        break;
      case this.reportTopicMessageId:
        if (response.message) {
          this.handleReportSuccessModal();
          this.handleReportModal();
        }
        break;
      case this.getSubjectNameMessageId:
        if (response.data) {
          this.handleSubject(response.data);
        }
        break;
      default:
        break;
    }
    // Customizable Area End
  }

  // web events
  handleSubject = (data: SubjectData[]) => {
    const subjectId = this.props.navigation.getParam("subjectId");
    const subjectData = data.filter((subject) => subject.id === subjectId)[0];
    const breadcrumb = this.breadcrumb;
    breadcrumb[1].label = subjectData.attributes.name;
    const propPassingSTRC = new Message(
      getName(MessageEnum.NavigationBreadcrumbMessage)
    );
    propPassingSTRC.addData(
      getName(MessageEnum.BreadcrumbDataMessage),
      breadcrumb
    );
    this.send(propPassingSTRC);
    this.setState({
      loading: false,
    });
  };

  componentDidMount = async () => {
    this.getTopicRead();
    this.getSubjectName();
    const propPassingSTRC = new Message(
      getName(MessageEnum.NavigationBreadcrumbMessage)
    );
    propPassingSTRC.addData(
      getName(MessageEnum.BreadcrumbDataMessage),
      this.breadcrumb
    );
    this.send(propPassingSTRC);
  };

  componentDidUpdate = (prevProps: Props, _: S) => {
    if (
      prevProps.navigation.getParam("topicId") !==
      this.props.navigation.getParam("topicId")
    ) {
      this.getTopicRead();
    }
  };

  navigateToBookmarks = () => {
    const subjectId = this.props.navigation.getParam("subjectId");
    const chapterId = this.props.navigation.getParam("chapterId");
    const topicId = this.props.navigation.getParam("topicId");

    this.props.navigation.navigate("SynopsisBookmarks", {
      subjectId,
      chapterId,
      topicId,
    });
  };

  handleBookmarkModal = () => {
    this.setState({
      modalBookmark: !this.state.modalBookmark,
    });
  };

  handleBookmark = (response: { attributes: { highlighted_text: string } }) => {
    this.setState({
      highlights: response.attributes.highlighted_text,
    });
  };

  handleTopicReadData2 = (response: TopicData[], chapterName: string) => {
    const topicId = this.props.navigation.getParam("topicId");
    const breadcrumb = this.breadcrumb;
    breadcrumb[2].label = chapterName;
    const propPassingSTRC = new Message(
      getName(MessageEnum.NavigationBreadcrumbMessage)
    );
    propPassingSTRC.addData(
      getName(MessageEnum.BreadcrumbDataMessage),
      breadcrumb
    );
    this.send(propPassingSTRC);

    let currentPage = 0;
    response.forEach((data, index) => {
      if (data.id === topicId) {
        currentPage = index + 1;
      }
    });

    this.setState({
      topicsData: response,
      loading: false,
      currentPage,
    });
  };

  handleTextArea = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({
      ...this.state,
      [name]: value,
    });
  };

  // Customizable Area Start
  getTopicRead = async () => {
    const subjectId = this.props.navigation.getParam("subjectId");
    const chapterId = this.props.navigation.getParam("chapterId");
    const topicId = this.props.navigation.getParam("topicId");
    const highlighted = this.props.navigation.getParam("highlighted");
    storage.set(
      "continueReading",
      JSON.stringify({ subjectId, chapterId, topicId, highlighted })
    );

    if (highlighted === "on") {
      this.fetchBookmark();
    }

    this.setState({
      loading: true,
    });

    const headers = {
      token: await storage.get("authToken"),
      "Content-Type": "application/json",
    };

    const getTopicReadSynopsisMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTopicReadSynopsisMessageId = getTopicReadSynopsisMessage.messageId;

    getTopicReadSynopsisMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/topic_read/${this.props.navigation.getParam(
        "topicId"
      )}?chapter_id=${this.props.navigation.getParam("chapterId")}`
    );

    getTopicReadSynopsisMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getTopicReadSynopsisMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(getTopicReadSynopsisMessage.id, getTopicReadSynopsisMessage);
  };

  fetchBookmark = async () => {
    const headers = {
      token: await storage.get("authToken"),
    };

    const fetchBookmarkMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.fetchBookmarkMessageId = fetchBookmarkMessage.messageId;

    fetchBookmarkMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_content_management/bookmarks?topic_id=${this.props.navigation.getParam(
        "topicId"
      )}`
    );

    fetchBookmarkMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    fetchBookmarkMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(fetchBookmarkMessage.id, fetchBookmarkMessage);
  };

  handleRatedStars = (index: number) => {
    this.setState({
      starsIndex: index,
    });
  };

  handleStarsModal = () => {
    this.setState({
      rateModal: !this.state.rateModal,
    });
  };

  handleStarsNoteModal = () => {
    this.setState({
      rateNoteModal: !this.state.rateNoteModal,
      rateModal: false,
    });
  };

  handleReportModal = () => {
    this.setState({
      reportModal: !this.state.reportModal,
    });
  };

  getSubjectName = async () => {
    const headers = {
      token: await storage.get("authToken"),
    };

    this.setState({
      loading: true,
    });

    const getSubjectTitleMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSubjectNameMessageId = getSubjectTitleMessage.messageId;

    getSubjectTitleMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `all_subjects`
    );

    getSubjectTitleMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getSubjectTitleMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getSubjectTitleMessage.id, getSubjectTitleMessage);
  };

  navigateToTopicRead = (value: number) => {
    this.props.navigation.navigate("SynopsisTopicRead", {
      subjectId: this.props.navigation.getParam("subjectId"),
      chapterId: this.props.navigation.getParam("chapterId"),
      topicId: this.state.topicsData[value - 1].id,
      highlighted: "off",
    });
  };

  handleTopicReadData = (data: TopicData) => {
    this.getTopicsToRead();

    const breadcrumb = this.breadcrumb;
    breadcrumb[3].label = data.attributes.name + " Read";
    const propPassingSTRC = new Message(
      getName(MessageEnum.NavigationBreadcrumbMessage)
    );
    propPassingSTRC.addData(
      getName(MessageEnum.BreadcrumbDataMessage),
      breadcrumb
    );
    this.send(propPassingSTRC);

    this.setState({
      topic: data,
    });
  };

  getTopicsToRead = async () => {
    const headers = {
      token: await storage.get("authToken"),
    };

    const getTopicsToReadMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getTopicsMessageId = getTopicsToReadMessage.messageId;

    getTopicsToReadMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `chapter_topics/${this.props.navigation.getParam("chapterId")}`
    );

    getTopicsToReadMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getTopicsToReadMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getTopicsToReadMessage.id, getTopicsToReadMessage);
  };

  saveBookmark = async () => {
    const headers = {
      token: await storage.get("authToken"),
      "Content-Type": "application/json",
    };

    const saveBookmarkMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.saveBookmarMessageId = saveBookmarkMessage.messageId;

    saveBookmarkMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        bookmarkable_id: this.props.navigation.getParam("topicId"),
        bookmarkable_type: "Topic",
        highlighted_text: this.state.highlights,
      })
    );
    saveBookmarkMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_content_management/bookmarks`
    );

    saveBookmarkMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    saveBookmarkMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(saveBookmarkMessage.id, saveBookmarkMessage);
  };

  navigateToNewNote = () => {
    this.props.navigation.navigate("SynopsisAddEditNotes", {
      subjectId: this.props.navigation.getParam("subjectId"),
      topicId: this.props.navigation.getParam("topicId"),
      chapterId: this.props.navigation.getParam("chapterId"),
      noteType: "New",
      noteId: "none",
    });
  };

  handleRateSuccessModal = () => {
    this.setState({
      rateSuccessModal: !this.state.rateSuccessModal,
    });
  };

  handleReportSuccessModal = () => {
    this.setState({
      reportSuccessModal: !this.state.reportSuccessModal,
    });
  };

  handleNotesModal = () => {
    this.setState({
      notesModal: !this.state.notesModal,
    });
  };

  reportTopic = async () => {
    const headers = {
      token: await storage.get("authToken"),
      "Content-Type": "application/json",
    };

    const reportTopicMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.reportTopicMessageId = reportTopicMessage.messageId;

    reportTopicMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        topic_id: this.props.navigation.getParam("topicId"),
        description: this.state.reportText,
      })
    );
    reportTopicMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_assessmenttest/topic_reports`
    );

    reportTopicMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    reportTopicMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(reportTopicMessage.id, reportTopicMessage);
  };

  rateTopic = async () => {
    const headers = {
      token: await storage.get("authToken"),
      "Content-Type": "application/json",
    };

    const rateTopicMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.rateTopicMessageId = rateTopicMessage.messageId;

    rateTopicMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        topic_id: this.props.navigation.getParam("topicId"),
        rating: this.state.starsIndex + 1,
        feedback_message: this.state.rateText,
      })
    );
    rateTopicMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_assessmenttest/topic_reviews`
    );

    rateTopicMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    rateTopicMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(rateTopicMessage.id, rateTopicMessage);
  };
  // Customizable Area End
}
