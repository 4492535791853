import React from "react";

import {
  Box as MUIBox,
  // Customizable Area Start
  withStyles,
  createStyles,
  Typography,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import Loader from "../../../components/src/Loader.web";
import { Chart } from "react-google-charts";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// Customizable Area End

import UsageStatsPracticeTestController, {
  configJSON,
  Props,
} from "./UsageStatsPracticeTestController.web";

class UsageStatsPracticeTest extends UsageStatsPracticeTestController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  RenderOverallChart = () => {
    if (this.state.statsPackage) {
      const dataOverall = [
        ["Category", "Percentage"],
        [
          "Correct Answers",
          this.state.statsPackage.exam_wise_overall_correct_percentage,
        ],
        [
          "Wrong Answers",
          this.state.statsPackage.exam_wise_overall_wrong_percentage,
        ],
        [
          "Unattempted Questions",
          this.state.statsPackage.exam_wise_overall_unattempted_percentage,
        ],
      ];

      const options = {
        slices: {
          0: { color: "#5407E0" },
          1: { color: "#681D74" },
          2: { color: "#8E2DE2" },
        },
        background: "none",
      };

      return (
        <MUIBox>
          <Typography className={this.props.classes.typoCenterBold}>
            {this.state.selectedPackage} overall test taken -{" "}
            {this.state.statsPackage.subject_wise_overall_attempts}
          </Typography>
          <Chart
            chartType="PieChart"
            data={dataOverall}
            width={"100%"}
            height={"700px"}
            options={options}
            className={this.props.classes.chart}
          />
        </MUIBox>
      );
    }

    return <></>;
  };

  RenderSubjectOverAllChart = () => {
    const packageStat = this.state.statsPackage;

    const subjectStat = this.state.statsSubject;

    const testStat = this.state.statsChapter;

    if (packageStat) {
      const dataSubjectTestOverall = [
        ["", "Correct", "Wrong", "Unattempted"],
        ...packageStat.subject_wise_attempts?.map((subject) => {
          return [
            subject.subject,
            subject.correct_answer_percentage,
            subject.incorrect_answer_percentage,
            subject.unattended_percentage,
          ];
        }),
      ];

      const dataSubjectTest = [
        ["Category", "Percentage"],
        [
          "Correct Answers",
          subjectStat?.subject_wise_overall_correct_percentage,
        ],
        ["Wrong Answers", subjectStat?.subject_wise_overall_wrong_percentage],
        [
          "Unattempted Questions",
          subjectStat?.subject_wise_overall_unattempted_percentage,
        ],
      ];

      const dataChapterTest = [
        ["Category", "Percentage"],
        [
          "Correct Answers",
          testStat?.subject_chapter_wise_overall_correct_percentage,
        ],
        [
          "Wrong Answers",
          testStat?.subject_chapter_wise_overall_wrong_percentage,
        ],
        [
          "Unattempted Questions",
          testStat?.subject_chapter_wise_overall_unattempted_percentage,
        ],
      ];

      return (
        <MUIBox>
          {this.state.selectedPackage &&
            !this.state.selectedSubject &&
            !this.state.selectedChapter && (
              <Chart
                chartType="Bar"
                data={dataSubjectTestOverall}
                width={"100%"}
                height={"400px"}
                className={this.props.classes.chart}
              />
            )}
          {this.state.selectedSubject && !this.state.selectedChapter && (
            <Chart
              chartType="PieChart"
              data={dataSubjectTest}
              width={"100%"}
              height={"700px"}
              className={this.props.classes.chart}
            />
          )}
          {this.state.selectedChapter && (
            <Chart
              chartType="PieChart"
              data={dataChapterTest}
              width={"100%"}
              height={"700px"}
              className={this.props.classes.chart}
            />
          )}
        </MUIBox>
      );
    }

    return <></>;
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <MUIBox>
        <Loader loading={this.state.loading} />
        <MUIBox padding="2em">
          <MUIBox className={this.props.classes.packageSelections}>
            {this.state.packages.map((packageData) => {
              return (
                <button
                  data-test-id="select-btn"
                  onClick={() =>
                    this.onChangeSelectPackage(
                      packageData.attributes.package_name
                    )
                  }
                  key={packageData.id}
                >
                  {packageData.attributes.package_name}
                </button>
              );
            })}
          </MUIBox>
          <MUIBox>
            {this.state.statsPackage && this.RenderOverallChart()}
          </MUIBox>
          <MUIBox position={"relative"}>
            <select
              onChange={(event) =>
                this.onChangeSelectSubject(event.target.value)
              }
              value={this.state.selectedSubject}
              data-test-id="select-btn"
              className={this.props.classes.selectStyle}
            >
              <option value="" disabled hidden>
                {configJSON.subjectNotSelected}
              </option>
              <option value={"{}"}> </option>
              {this.state.subjects.map((subject) => {
                return (
                  <option value={subject.attributes.name} key={subject.id}>
                    {subject.attributes.name}
                  </option>
                );
              })}
            </select>
            <ExpandMoreIcon className={this.props.classes.expandMoreIcon} />
          </MUIBox>
          <MUIBox position={"relative"} marginBottom={"5em"}>
            <select
              onChange={(event) =>
                this.onChangeSelectChapter(event.target.value)
              }
              value={this.state.selectedChapter}
              data-test-id="select-btn"
              className={this.props.classes.selectStyle}
            >
              <option value="" disabled hidden>
                CHAPTER : NOT SELECTED
              </option>
              <option value={"{}"}> </option>
              {this.state.chapters.map((chapter) => {
                return (
                  <option value={chapter.attributes.name} key={chapter.id}>
                    {chapter.attributes.name}
                  </option>
                );
              })}
            </select>
            <ExpandMoreIcon className={this.props.classes.expandMoreIcon} />
          </MUIBox>
          {this.RenderSubjectOverAllChart()}
        </MUIBox>
      </MUIBox>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyleUsageStatsPracticeTest = () =>
  createStyles({
    chart: {
      "& rect": {
        fill: "#FAFAFA!important",
      },
    },
    selectStyle: {
      width: "100%",
      fontSize: "1.4em",
      WebkitAppearance: "none",
      background: "rgb(250,231,231)",
      height: "70px",
      color: "#5C0F89",
      fontWeight: 500,
      paddingLeft: "1.5em",
      outline: 0,
      border: "none",
      marginTop: "1.3em",
    },
    expandMoreIcon: {
      background:
        "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
      borderRadius: "50%",
      right: "30px",
      top: "47px",
      color: "white",
      position: "absolute",
      cursor: "pointer",
    },
    packageSelections: {
      width: "100%",
      minHeight: "150px",
      height: "max-content",
      padding: "1.3em",
      background: "#eee",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginBottom: "3em",
      gap: "3em",
      "& button": {
        border: "none",
        fontWeight: 500,
        fontSize: "1.4em",
        cursor: "pointer",
        color: "#808080",
      },
    },
    typoCenterBold: {
      fontWeight: 700,
      fontSize: "1.5em",
      textAlign: "center",
    },
  });
export default withStyles(webStyleUsageStatsPracticeTest)(
  UsageStatsPracticeTest
);
export { UsageStatsPracticeTest };
// Customizable Area End
