export const muiPaginationStyle = {
  borderRadius: "4px",
  position: "relative" as const,
  border: "1px solid rgba(255, 58, 87, 0.7)",
  width: "max-content",
  "& li": {
    color: "white",
    width: "35px",
    background: "white",
    height: "41px",
    borderRadius: "2px",
  },
  "& li:nth-child(1)": {
    background: "transparent",
    position: "absolute",
    zIndex: 999,
    left: -20,
    top: 10,
    "& button": {
      height: "max-content",
      width: "max-content",
    },
    "& button.Mui-disabled": {
      display: "none",
    },
    "& button svg": {
      background:
        "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
      borderRadius: "50%",
      "& path": {
        fill: "white",
      },
    },
  },
  "& li:nth-last-child(1)": {
    background: "transparent",
    position: "absolute",
    right: -20,
    top: 10,
    zIndex: 999,
    "& button svg": {
      background:
        "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
      borderRadius: "50%",
      "& path": {
        fill: "white",
      },
    },
    "& button": {
      height: "max-content",
      width: "max-content",
    },
    "& button.Mui-disabled": {
      display: "none",
    },
  },
  "& li button:hover": {
    background: "none",
  },
  "& li button": {
    height: "100%",
    alignItems: "center",
    width: "100%",
    background: "none",
    justifyContent: "center",
    margin: 0,
    display: "flex",
    padding: 0,
  },
  "& li div": {
    height: "100%",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    background: "none",
    display: "flex",
  },
  "& li button.Mui-selected": {
    color: "white",
    background:
      "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
    fontWeight: 500,
    borderRadius: "2px",
  },
};
