import React from "react";

import {
  Box,
  // Customizable Area Start
  withStyles,
  createStyles,
  Typography,
  Button,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import Loader from "../../../components/src/Loader.web";
import { Chart } from "react-google-charts";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// Customizable Area End

import UsageStatsLiveClassController, {
  configJSON,
  Props,
} from "./UsageStatsLiveClassController.web";

class UsageStatsLiveClass extends UsageStatsLiveClassController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  RenderOverallChart = () => {
    if (this.state.liveClassStats) {
      const dataOverall = [
        ["Category", "Percentage"],
        ["Attended", this.state.liveClassStats.attended_percentage],
        ["Unattended", this.state.liveClassStats.unattended_percentage],
      ];

      const options = {
        slices: {
          0: { color: "#5407E0" },
          1: { color: "#8E2DE2" },
        },
        background: "none",
      };

      return (
        <Box>
          <Chart
            chartType="PieChart"
            data={dataOverall}
            width={"100%"}
            height={"700px"}
            options={options}
            className={this.props.classes.chart}
          />
          <Box marginBottom="5em" textAlign={"center"}>
            <Typography className={this.props.classes.resultsTypo}>
              In subject {this.state.selectedChoice} you have attended{" "}
              {this.state.liveClassStats.attended_percentage}% live classes
            </Typography>
            <Typography className={this.props.classes.resultsTypo}>
              {this.state.liveClassStats.unattended_percentage}% of{" "}
              {this.state.selectedChoice} classes you didn't attend
            </Typography>
          </Box>
        </Box>
      );
    }

    return <></>;
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box>
        <Loader loading={this.state.loading} />
        <Box padding="2em">
          <Typography className={this.props.classes.showStatsTypo}>
            Select subject to show stats
          </Typography>
          <Box position={"relative"}>
            <select
              onChange={(event) =>
                this.onChangeSelectSubject(event.target.value)
              }
              value={this.state.selectedChoice}
              data-test-id="select-btn"
              className={this.props.classes.selectStyle}
            >
              <option value="" disabled hidden>
                {configJSON.subjectNotSelected}
              </option>
              <option value=""> </option>
              {this.state.liveClassSubjects.map((data) => (
                <option key={`${data}-subject-choice`} value={data}>
                  {data}
                </option>
              ))}
            </select>
            <ExpandMoreIcon className={this.props.classes.expandMoreIcon} />
          </Box>
          {this.RenderOverallChart()}
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyleUsageStatsLiveClass = () =>
  createStyles({
    selectStyle: {
      width: "100%",
      outline: 0,
      fontSize: "1.4em",
      WebkitAppearance: "none",
      height: "70px",
      marginTop: "1.3em",
      color: "#5C0F89",
      paddingLeft: "1.5em",
      fontWeight: 500,
      border: "1px solid black",
    },
    expandMoreIcon: {
      background:
        "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
      color: "white",
      top: "47px",
      right: "30px",
      position: "absolute",
      cursor: "pointer",
      borderRadius: "50%",
    },
    chart: {
      "& rect": {
        fill: "#FAFAFA!important",
      },
    },
    typeOverall: {
      fontWeight: 500,
      fontSize: "1.2rem",
    },
    showStatsTypo: {
      fontWeight: 500,
      fontSize: "1.3em",
    },
    resultsTypo: {
      fontWeight: 500,
      fontSize: "1.3em",
    },
  });
export default withStyles(webStyleUsageStatsLiveClass)(UsageStatsLiveClass);
export { UsageStatsLiveClass };
// Customizable Area End
