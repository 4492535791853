import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import storage from "../../../framework/src/StorageProvider.web";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import { IApplicationOpened } from "./ApplicationNewOpeningController.web";
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  application: IApplicationOpened | null;
  loading: boolean;
  currentPageApplication: number;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ApplicationDocumentUploadController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getDocumentUploadsMessageId = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      application: null,
      loading: false,
      currentPageApplication: 1,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getDocumentUploadsMessageId && response) {
      if (response?.data) {
        this.handleSuccessfulApplicationsFetch(response.data);
      }
    }
    // Customizable Area End
  }

  // web events
  componentDidMount = async () => {
    this.getDocumentUploads();
  };

  handleSuccessfulApplicationsFetch = (
    fetchedApplication: IApplicationOpened[]
  ) => {
    const search = this.props.navigation.history.location.search;
    const params = new URLSearchParams(search);
    const univId = params.get("id");

    const application =
      fetchedApplication.find((data) => data.id === univId) || null;

    this.setState({
      loading: false,
      application,
    });
  };

  // Customizable Area Start
  getDocumentUploads = async () => {
    this.setState({
      loading: true,
    });

    const getDocumentUploadsMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const headers = {
      token: await storage.get("authToken"),
    };

    getDocumentUploadsMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_tutorials2/guidance_applications/applications_opened`
    );
    this.getDocumentUploadsMessageId = getDocumentUploadsMessage.messageId;
    getDocumentUploadsMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getDocumentUploadsMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(
      getDocumentUploadsMessage.id,
      getDocumentUploadsMessage
    );
  };
  // Customizable Area End
}
