import React from "react";

import {
  Box as DivBox,
  // Customizable Area Start
  withStyles,
  Modal,
  createStyles,
  Button,
  Typography,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import {
  saveImg,
  bookmarkImg,
  report,
  star,
  starFillIcon,
  starEmptyIcon,
  submitIcon,
} from "./assets";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { NavLink } from "react-router-dom";
import Loader from "../../../components/src/Loader.web";
import Pagination from "@material-ui/lab/Pagination";
// Customizable Area End

import SynopsisTopicReadController, {
  Props,
} from "./SynopsisTopicReadController.web";

class SynopsisTopicRead extends SynopsisTopicReadController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const param = this.props.navigation.getParam;
    return (
      // Customizable Area Start
      <DivBox position="relative">
        <Loader loading={this.state.loading} />
        <Modal
          onClose={this.handleBookmarkModal}
          open={this.state.modalBookmark}
        >
          <DivBox className={this.props.classes.modalStyle}>
            <CheckCircleIcon style={{ color: "ff5d5b", fontSize: "3.5em" }} />
            <h3>Bookmark saved successfully</h3>
            <DivBox>
              <Button
                className={this.props.classes.buttonStyleYes}
                data-test-id="submitBtnModal"
                onClick={this.navigateToBookmarks}
              >
                Go to saved bookmarks
              </Button>
            </DivBox>
          </DivBox>
        </Modal>
        <DivBox className={this.props.classes.bannerStyle}>
          <Typography variant="h5" style={{ margin: "0 auto" }}>
            {this.state.topic?.attributes.name}
          </Typography>
          <DivBox>
            <p className={this.props.classes.savedNotesIcon}>
              <img
                src={saveImg}
                width={"25px"}
                onClick={this.handleNotesModal}
                data-test-id="notes-modal"
              />
            </p>
            <p className={this.props.classes.bookmarkIcon}>
              <img
                src={bookmarkImg}
                width={"25px"}
                onClick={this.saveBookmark}
                data-test-id="bookmark-modal"
              />
            </p>
          </DivBox>
        </DivBox>
        <DivBox
          padding={"2em"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <DivBox className={this.props.classes.synopsisMaterialContainer}>
            <div
              dangerouslySetInnerHTML={{
                __html: this.state.topic?.attributes.synopsis as string,
              }}
            />
          </DivBox>
        </DivBox>
        <DivBox
          display="flex"
          flexDirection={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          position="relative"
          marginBottom={"5em"}
        >
          <Pagination
            data-test-id="pagination"
            count={this.state.topicsData.length}
            page={this.state.currentPage}
            onChange={(_, value) => this.navigateToTopicRead(value)}
            className={this.props.classes.muiPaginationStyle}
          />
          <DivBox
            display="flex"
            gridGap={"1em"}
            position={"absolute"}
            right={"2em"}
          >
            <DivBox
              style={{
                border: "none",
                borderRadius: "50%",
                background: "#FFDFE1",
                width: "50px",
                height: "50px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
              data-test-id="report-btn"
              onClick={this.handleReportModal}
            >
              <img src={report} style={{ width: "25px", height: "25px" }} />
            </DivBox>
            <DivBox
              style={{
                border: "none",
                borderRadius: "50%",
                background: "#FFDFE1",
                width: "50px",
                height: "50px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
              data-test-id="rate-btn"
              onClick={this.handleStarsModal}
            >
              <img src={star} style={{ width: "25px", height: "25px" }} />
            </DivBox>
          </DivBox>
        </DivBox>
        <Modal open={this.state.rateModal} onClose={this.handleStarsModal}>
          <DivBox className={this.props.classes.modalStyle}>
            <Typography
              variant="h6"
              style={{ padding: "0 10%", textAlign: "center" }}
            >
              How likely would you be to recommend myrank to a friend or
              colleague?
            </Typography>
            <DivBox
              display={"flex"}
              flexDirection={"column"}
              gridGap={"1em"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <DivBox
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                gridGap={"1em"}
              >
                {Array.from(Array(10).keys()).map((_, index) => {
                  return (
                    <>
                      {this.state.starsIndex >= index ? (
                        <img
                          width={"40px"}
                          src={starFillIcon}
                          alt="filled"
                          onClick={() => this.handleRatedStars(index)}
                          style={{ cursor: "pointer" }}
                        />
                      ) : (
                        <img
                          width={"40px"}
                          src={starEmptyIcon}
                          alt="empty"
                          onClick={() => this.handleRatedStars(index)}
                          style={{ cursor: "pointer" }}
                          data-test-id="rate-btn-star"
                        />
                      )}
                    </>
                  );
                })}
              </DivBox>
              <Button
                onClick={this.handleStarsNoteModal}
                data-test-id="rate-btn-message"
                className={this.props.classes.messageStyle}
              >
                Send Message
              </Button>
            </DivBox>
          </DivBox>
        </Modal>
        <Modal
          open={this.state.rateNoteModal}
          onClose={this.handleStarsNoteModal}
        >
          <DivBox className={this.props.classes.modalStyle}>
            <Typography variant="h6">How can we do better?</Typography>
            <DivBox
              display={"flex"}
              flexDirection={"column"}
              gridGap={"1em"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <textarea
                name="rateText"
                value={this.state.rateText}
                onChange={(event) => this.handleTextArea(event)}
                data-test-id="rate-btn-text"
                className={this.props.classes.messageBoxStyle}
              />
              <DivBox>
                <Button
                  onClick={this.handleStarsNoteModal}
                  className={this.props.classes.cancelStyle}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => this.rateTopic()}
                  data-test-id="rate-btn-send"
                  className={this.props.classes.messageStyle2}
                >
                  Send
                </Button>
              </DivBox>
            </DivBox>
          </DivBox>
        </Modal>
        <Modal open={this.state.reportModal} onClose={this.handleReportModal}>
          <DivBox className={this.props.classes.modalStyle}>
            <Typography>What do you want to report?</Typography>
            <DivBox
              display={"flex"}
              flexDirection={"column"}
              gridGap={"1em"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <textarea
                name="reportText"
                value={this.state.reportText}
                onChange={(event) => this.handleTextArea(event)}
                data-test-id="report-btn-text"
                className={this.props.classes.messageBoxStyle}
              />
              <DivBox>
                <Button
                  onClick={this.handleReportModal}
                  className={this.props.classes.cancelStyle}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => this.reportTopic()}
                  data-test-id="report-btn-send"
                  className={this.props.classes.messageStyle2}
                >
                  Send
                </Button>
              </DivBox>
            </DivBox>
          </DivBox>
        </Modal>
        <Modal
          open={this.state.rateSuccessModal}
          onClose={this.handleRateSuccessModal}
        >
          <DivBox className={this.props.classes.modalStyle}>
            <img src={submitIcon} alt="success" />
            <Typography variant={"h6"} style={{ fontWeight: 700 }}>
              Feedback Submitted Succesfully!
            </Typography>
            <Button
              onClick={() => {
                this.handleRateSuccessModal();
              }}
              className={this.props.classes.buttonStyle}
            >
              Go Back
            </Button>
          </DivBox>
        </Modal>
        <Modal
          open={this.state.reportSuccessModal}
          onClose={this.handleReportSuccessModal}
        >
          <DivBox className={this.props.classes.modalStyle}>
            <img src={submitIcon} alt="success" />
            <Typography variant={"h6"} style={{ fontWeight: 700 }}>
              Feedback Submitted Succesfully!
            </Typography>
            <Button
              onClick={() => {
                this.handleReportSuccessModal();
              }}
              className={this.props.classes.buttonStyle}
            >
              Go Back
            </Button>
          </DivBox>
        </Modal>
        <Modal open={this.state.notesModal} onClose={this.handleNotesModal}>
          <DivBox className={this.props.classes.modalStyle}>
            <Typography variant="h6">Choose What You Need</Typography>
            <DivBox
              display={"flex"}
              flexDirection={"column"}
              gridGap={"1em"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <NavLink
                to={`/dashboard/synopsis/chapters/${param(
                  "subjectId"
                )}/topics/${[param("chapterId")]}/saved-notes/${param(
                  "topicId"
                )}`}
              >
                <Button className={this.props.classes.buttonStyleNo}>
                  Go to saved notes
                </Button>
              </NavLink>
              <Button
                className={this.props.classes.buttonStyleNo}
                onClick={this.navigateToNewNote}
              >
                Create New Note
              </Button>
            </DivBox>
          </DivBox>
        </Modal>
      </DivBox>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyleSynopsisTopicRead = () =>
  createStyles({
    bannerStyle: {
      display: "flex",
      height: "71px",
      background: "rgb(244,239,246)",
      justifyContent: "flex-end",
      alignItems: "center",
      padding: "0 2em",
      width: "100%",
      "& h5": {
        fontWeight: 600,
        color: "#702F8C",
      },
      position: "relative",
    },
    synopsisMaterialContainer: {
      overflow: "hidden",
      padding: "1em 2em",
      background: "#eee",
      height: "700px",
      width: "100%",
      "& .header-class": {
        marginBottom: "2em",
        color: "#702F8C",
      },
      "&::-webkit-scrollbar": {
        width: "8px",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "4px",
        background: "#888",
      },
      "&::-webkit-scrollbar-track": {
        background: "#f1f1f1",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        background: "#555",
      },
      "&:hover": {
        overflowY: "scroll",
      },
      "& > div:nth-child(1) img": {
        maxWidth: "100%",
        width: "max-content !important",
        height: "auto !important",
      },
      "& > div:nth-child(1)": {
        "& *": {
          wordWrap: "break-word",
        },
        "& table": {
          width: "100%",
          borderCollapse: "collapse",
        },

        "& thead": {
          border: "1px solid #E8E8E8",
          background: "#E8E8E8",
          "& tr th:last-child": {
            borderTopRightRadius: "0.7em",
          },
          "& tr th:nth-child(1)": {
            borderTopLeftRadius: "0.7em",
          },
        },
        "& tbody": {
          "& tr:last-child td:nth-child(1)": {
            borderBottomLeftRadius: "0.7em",
          },
          "& tr td": {
            padding: "1.2em 1.2em",
            wordWrap: "break-word",
            overflowWrap: "break-word",
          },
          "& tr:last-child td:last-child": {
            borderBottomRightRadius: "0.7em",
          },
          "& tr:nth-child(even)": {
            background: "#E8E8E8",
          },
        },

        "& h1": {
          fontSize: "1.5rem",
          fontWeight: "bold",
          margin: "1.5rem 0 1rem",
        },

        "& h2": {
          fontSize: "1.3rem",
          fontWeight: "bold",
          margin: "1.25rem 0 1rem",
        },

        "& h3": {
          fontSize: "1.2rem",
          fontWeight: "bold",
          margin: "1rem 0 0.75rem",
        },

        "& h4": {
          fontSize: "1.1rem",
          fontWeight: "bold",
          margin: "0.75rem 0 0.5rem",
        },

        "& h5": {
          fontSize: "1rem",
          fontWeight: "bold",
          margin: "0.5rem 0 0.25rem",
        },

        "& h6": {
          fontSize: "1rem",
          fontWeight: "bold",
          margin: "0.25rem 0",
        },

        "& p": {
          margin: "0 0 1rem",
        },

        "& ul, & ol": {
          margin: "0 0 1rem",
          paddingLeft: "20px",
        },

        "& li": {
          margin: "0 0 0.5rem",
        },
      },
      "& > div:nth-child(1) table": {
        tableLayout: "fixed",
      },
    },
    buttonStyleNo: {
      minWidth: "230px",
      width: "max-content",
      background: "white",
      border: "1px solid #FF5B5B",
      height: "55px",
      borderRadius: "0.6em",
      color: "#222",
      textTransform: "none" as const,
      transition: "all 0.3s ease",
      fontSize: "1.35em",
      "&:hover": {
        color: "white",
        background:
          "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
      },
    },
    buttonStyleYes: {
      height: "50px",
      color: "white",
      background:
        "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
      minWidth: "130px",
      width: "max-content",
      textTransform: "none" as const,
      marginLeft: "1em",
    },
    navigationStyle: {
      border: "1px solid #FC5859",
      borderRadius: "0.5em",
      height: "max-content",
      fontWeight: 500,
      "& a": {
        color: "black",
        padding: "1.2em 1.3em",
        background: "white",
        borderRadius: "0.4em",
      },
      "& .active": {
        color: "white",
        padding: "1.2em 1.3em",
        background:
          "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
        borderRadius: "0.4em",
      },
    },
    savedNotesIcon: {
      position: "absolute",
      right: "10%",
      top: "20%",
      cursor: "pointer",
      color: "black",

      "&::before": {
        content: '"Notes"',
        position: "absolute",
        top: "100%",
        left: "-40%",
        width: "100px",
        height: "30px",
      },
    },
    modalStyle: {
      background: "white",
      display: "flex",
      flexDirection: "column",
      transform: "translate(-50%, -50%)",
      width: "35%",
      minHeight: "max-content",
      justifyContent: "center",
      alignItems: "center",
      position: "absolute" as "absolute",
      left: "50%",
      bgcolor: "background.paper",
      border: "2px solid #FFD0D8",
      borderRadius: "1.5em",
      padding: "2em",
      top: "50%",
      outline: "none",
      gap: "1.5em",
    },
    bookmarkIcon: {
      position: "absolute",
      top: "20%",
      right: "5%",
      cursor: "pointer",
      color: "black",

      "&::before": {
        content: '"Bookmark"',
        position: "absolute",
        top: "100%",
        left: "-100%",
        width: "100px",
        height: "30px",
      },
    },
    buttonStyle: {
      border: "1px solid #BE888E",
      borderRadius: "0.6em",
      textTransform: "none" as const,
      "&:hover": {
        background:
          "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
        color: "white",
      },
      minWidth: "130px",
      width: "max-content",
      height: "50px",
      color: "#4F1f6d",
    },
    messageStyle: {
      background:
        "linear-gradient(90deg,rgba(255, 63, 89, 1) 0%,rgba(254, 98, 86, 1) 100%)",
      borderRadius: "0.7em",
      width: "200px",
      height: "50px",
      color: "white",
      marginTop: "2em",
    },
    messageStyle2: {
      borderRadius: "0.7em",
      background:
        "linear-gradient(90deg,rgba(255, 63, 89, 1) 0%,rgba(254, 98, 86, 1) 100%)",
      width: "150px",
      color: "white",
      margin: "2em 1em",
      height: "40px",
    },
    cancelStyle: {
      height: "40px",
      margin: "2em 1em",
      border: "1px solid #FD6455",
      borderRadius: "0.7em",
      width: "150px",
      color: "#522C6D",
    },
    messageBoxStyle: {
      border: "1px solid #FD6455",
      resize: "none",
      borderRadius: "0.7em",
      width: "600px",
      height: "200px",
      padding: "1em",
    },
    muiPaginationStyle: {
      borderRadius: "4px",
      position: "relative",
      width: "max-content",
      border: "1px solid rgba(255, 58, 87, 0.7)",
      "& li": {
        color: "white",
        height: "41px",
        background: "white",
        borderRadius: "2px",
        width: "35px",
      },
      "& li:nth-child(1)": {
        position: "absolute",
        left: -20,
        top: 10,
        background: "transparent",
        zIndex: 999,
        "& button": {
          height: "max-content",
          width: "max-content",
        },
        "& button svg": {
          background:
            "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
          borderRadius: "50%",
          "& path": {
            fill: "white",
          },
        },
        "& button.Mui-disabled": {
          display: "none",
        },
      },
      "& li:nth-last-child(1)": {
        position: "absolute",
        right: -20,
        top: 10,
        background: "transparent",
        zIndex: 999,
        "& button": {
          height: "max-content",
          width: "max-content",
        },
        "& button svg": {
          borderRadius: "50%",
          background:
            "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
          "& path": {
            fill: "white",
          },
        },
        "& button.Mui-disabled": {
          display: "none",
        },
      },
      "& li button:hover": {
        background: "none",
      },
      "& li button": {
        height: "100%",
        width: "100%",
        background: "none",
        justifyContent: "center",
        alignItems: "center",
        margin: 0,
        padding: 0,
        display: "flex",
      },
      "& li div": {
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "none",
      },
      "& li button.Mui-selected": {
        color: "white",
        background:
          "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
        fontWeight: 500,
        borderRadius: "2px",
      },
    },
  });
export default withStyles(webStyleSynopsisTopicRead)(SynopsisTopicRead);
export { SynopsisTopicRead };
// Customizable Area End
