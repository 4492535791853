Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.noDataAvalible =  "NO DATA FOUND"
exports.resgisterEndPoint = "/bx_block_appointment_management/cms_bookeds?status={status}"
exports.Rejected = "Rejected"
exports.Followup = "Followup"
exports.Registered = "Registered"
exports.appointment = "Appointment"
exports.UpdatePendingStatus = "Update Pending Status"
exports.upcomingAppointment = "Upcoming Appointments"
exports.cancel=  "Cancel"
exports.status  = "Status"
exports.timeslot= "Time Slot"
exports.zoomLink = "Zoom Link"
exports.Group = "Group"
exports.ParentName = "Parent Name"
exports.studentName = "Student Name"
exports.pendingAppointmentEndPoint = "bx_block_appointment_management/cms_bookeds/pending_stots"
exports.upcomingAppointmentEndPoint = "bx_block_appointment_management/cms_bookeds/upcoming_stots"
exports.todaysAppoinmentEndPoint = "bx_block_appointment_management/cms_bookeds/today_stots"
exports.avaliable = "Available"
exports.TodayAppointments = "Today's Appointments"
exports.dashboardGetUrl = "/dashboard/dashboards";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.labelBodyText = "dashboard Body";
exports.allNotesHeader = "Saved Notes";
exports.allBookmarksHeader = "Bookmarks";
exports.viewButtonText = "View";
exports.viewNotesButtonText = "View Notes";
exports.allSubjectNotesHeader = "allSubjectNotesHeader";
exports.subject = "Subject : ";
exports.totalTopic = "Total Topic - ";
exports.totalNotes = "Saved Notes - ";
exports.particularNotesHeader = "particularNotesHeader";
exports.addNew = "Add New"
exports.getUserAuth = "userData"
exports.home = "Home"
exports.bookMySlot = "Book my Slot"
exports.courseFinder = "Course Finder"
exports.cutoff = "Cut Off Analysis"
exports.applications = "Applications"
exports.logout = "Logout"
exports.welcome = "Welcome to Guidance"
exports.packageDetailsEndPOint = "/account_block/profile_settings/my_packages"
exports.welcomeText = "Welcome to E-Learn"
exports.CMsToken ="eyJhbGciOiJIUzUxMiJ9.eyJpZCI6MTAzNywiZXhwIjoxNzEyMTQ0Nzc4LCJ0b2tlbl90eXBlIjoibG9naW4ifQ.sH7c-nUCFwcisIPZ9DXht8icvb5sQJW55LbYRg0k6cNhh-Maw6VymyT1cFTYHuOjrfvhSzffv6BCMlCeq8aZAQ"
exports.guestBookMySlotAPI = "bx_block_contact_us/guest_book_slots"
exports.packagesName = "Package Name"
exports.dashbordContinueWhereLeft = "/continue_reading_dashboard"
exports.dashboardRetakeTest = "/bx_block_assessmenttest/practice_tests/retake_test_dashboard"
exports.CMsToken ="eyJhbGciOiJIUzUxMiJ9.eyJpZCI6MTAzNywiZXhwIjoxNzEyMTQ0Nzc4LCJ0b2tlbl90eXBlIjoibG9naW4ifQ.sH7c-nUCFwcisIPZ9DXht8icvb5sQJW55LbYRg0k6cNhh-Maw6VymyT1cFTYHuOjrfvhSzffv6BCMlCeq8aZAQ"
exports.guestBookMySlotAPI = "bx_block_contact_us/guest_book_slots"
exports.helloText = "Hello";
exports.searchTxt = "Search";
exports.amountText = "Amount paid - ";
exports.continueText = "Continue where you left";
exports.subjectText = "Subject";
exports.chapterText = "Topic";
exports.liveClassesText = "Live class for today";
exports.seeAll = "See All";
exports.retakeText = "Retake Testes based on review analysis";
exports.takeTestText = "Take Test";
exports.attendClass = "Attend Class";
exports.bookAndSavedNotes = "Bookmaarks & Saved notes";
exports.bookmarkText = "Bookmarks";
exports.savedNotesText = "Saved Notes";
exports.viewText = "View All";
exports.Statistics = "Statistics"
// Customizable Area End