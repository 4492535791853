import React from "react";

import {
  Box,
  // Customizable Area Start
  withStyles,
  createStyles,
  Typography,
  Button,
  Modal,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { NavLink } from "react-router-dom";
import { filterBtn, sortBtn } from "./assets";
import SearchIcon from "@material-ui/icons/Search";
import Loader from "../../../components/src/Loader.web";
import Select, { OptionTypeBase, StylesConfig } from "react-select";
import Pagination from "@material-ui/lab/Pagination";
interface OptionType extends OptionTypeBase {
  value: string;
  label: string;
}
// Customizable Area End

import LiveClassController, { Props } from "./LiveClassController.web";

class LiveClass extends LiveClassController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    const currentClass = this.state.currentClass;
    const { academicYear, faculty, subject } = this.state.options;

    const colourStyles: StylesConfig<OptionType, false> = {
      input: (styles) => ({
        ...styles,
        color: "#510081",
      }),
      singleValue: (styles) => ({
        ...styles,
        color: "#510081",
      }),
      placeholder: (styles) => ({
        ...styles,
        color: "#510081",
      }),
      control: (styles) => ({
        ...styles,
        borderRadius: "0.5em",
        border: "1px solid #FF6B55",
        height: "51px",
        "&:hover": {
          outline: "none",
        },
        boxShadow: "none",
      }),
    };

    const { currentTable, currentPageCurrentTable } = this.state;

    const itemsPerPage = 10;
    const indexOfLastItem = currentPageCurrentTable * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = currentTable.slice(indexOfFirstItem, indexOfLastItem);
    const totalPages = Math.ceil(currentTable.length / itemsPerPage);

    return (
      // Customizable Area Start
      <Box>
        <Loader loading={this.state.loading} />
        <Modal
          open={this.state.modalCurrentFilter}
          onClose={this.handleModalFilterCurrent}
        >
          <Box className={this.props.classes.modalStyle}>
            <Typography variant={"h6"} style={{ fontWeight: 700 }}>
              Filter By
            </Typography>
            <Box
              display={"flex"}
              flexDirection={"column"}
              width={"100%"}
              gridGap={"1.5em"}
            >
              <Select
                isSearchable={true}
                isClearable={true}
                name="currentSubject"
                options={subject}
                placeholder="Subject"
                data-test-id="filter-subject"
                onChange={(event) =>
                  this.handleUserInputChangeCurrent(event, "currentSubject")
                }
                styles={colourStyles}
              />
              <input
                name="currentDate"
                type="date"
                data-test-id="filter-date"
                value={this.state.userInputCurrent.currentDate}
                onChange={(event) =>
                  this.handleUserInputChangeCurrent(
                    {
                      value: event.target.value,
                      label: "date",
                    },
                    "currentDate"
                  )
                }
                placeholder="Date"
              />
              <Select
                isSearchable={true}
                isClearable={true}
                name="currentFaculty"
                data-test-id="filter-faculty"
                options={faculty}
                placeholder="Faculty"
                onChange={(event) =>
                  this.handleUserInputChangeCurrent(event, "currentFaculty")
                }
                styles={colourStyles}
              />
              <Select
                isSearchable={true}
                options={academicYear}
                isClearable={true}
                name="currentAcademicYear"
                data-test-id="filter-year"
                onChange={(event) =>
                  this.handleUserInputChangeCurrent(
                    event,
                    "currentAcademicYear"
                  )
                }
                placeholder="Academic Year"
                styles={colourStyles}
              />
            </Box>
            <Box alignSelf={"center"}>
              <Button
                data-test-id="close-filter"
                onClick={this.handleModalFilterCurrent}
              >
                Cancel
              </Button>
              <Button
                data-test-id="filter-init"
                onClick={() =>
                  this.filterUpcomingPrevious(
                    currentClass,
                    {
                      faculty: this.state.userInputCurrent.currentFaculty,
                      date: this.state.userInputCurrent.currentDate,
                      subject_name: this.state.userInputCurrent.currentSubject,
                      academic_year:
                        this.state.userInputCurrent.currentAcademicYear,
                    },
                    "current"
                  )
                }
              >
                Done
              </Button>
            </Box>
          </Box>
        </Modal>
        <Modal
          open={this.state.modalCurrentSort}
          onClose={this.handleModalSortCurrent}
        >
          <Box className={this.props.classes.modalStyle}>
            <Typography variant={"h6"} style={{ fontWeight: 700 }}>
              Sort By
            </Typography>
            <Box
              display={"flex"}
              flexDirection={"column"}
              width={"100%"}
              gridGap={"1.5em"}
              justifyContent={"flex-start"}
              alignItems={"center"}
            >
              <label>
                <input
                  data-test-id="most-viewed"
                  type="checkbox"
                  className={this.props.classes.checkbox}
                  checked={this.state.selectedOption === "most-viewed"}
                  onChange={() => this.handleCheckboxChange("most-viewed")}
                />
                <p>Most Viewed</p>
              </label>
              <label>
                <input
                  data-test-id="recently-added"
                  type="checkbox"
                  className={this.props.classes.checkbox}
                  checked={this.state.selectedOption === "recently_added"}
                  onChange={() => this.handleCheckboxChange("recently_added")}
                />
                <p>Recently added</p>
              </label>
              <label>
                <input
                  data-test-id="ratings"
                  type="checkbox"
                  className={this.props.classes.checkbox}
                  checked={this.state.selectedOption === "ratings"}
                  onChange={() => this.handleCheckboxChange("ratings")}
                />
                <p>Ratings</p>
              </label>
            </Box>
            <Box alignSelf={"center"}>
              <Button
                data-test-id="close-filter"
                onClick={this.handleModalSortCurrent}
              >
                Cancel
              </Button>
              <Button
                data-test-id="sort-init"
                onClick={() =>
                  this.sortUpcomingPrevious(
                    currentClass,
                    this.state.selectedOption,
                    "current"
                  )
                }
              >
                Done
              </Button>
            </Box>
          </Box>
        </Modal>
        <Box
          display="flex"
          justifyContent={"center"}
          alignItems={"center"}
          padding={"2em 0"}
        >
          <Typography variant="h5" className={this.props.classes.headerStyle}>
            Live Classes
          </Typography>
        </Box>
        <Box className={this.props.classes.liveClassContainer}>
          <Box display={"flex"} flexWrap={"wrap"} gridGap={"1em"}>
            <Box className={this.props.classes.instructionsStyles}>
              <Typography variant="h5">Instructions</Typography>
              <ul>
                {this.state.instructions.map((element, index) => {
                  return (
                    <li key={`instruction-${index}`}>
                      {element.attributes.content}
                    </li>
                  );
                })}
              </ul>
            </Box>
            <Box className={this.props.classes.classStyles}>
              <Typography variant="h5">Class Timings</Typography>
              <ul>
                {this.state.classTiming.map((element, index) => {
                  return (
                    <li key={`class-${index}`}>{element.attributes.content}</li>
                  );
                })}
              </ul>
            </Box>
          </Box>
          <Box>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              marginTop={"3em"}
            >
              <Box
                display={"flex"}
                flexDirection={"row"}
                gridGap={"1em"}
                margin={"0 auto"}
              >
                <Button
                  data-test-id="upcoming-btn"
                  key={`upcoming-btn`}
                  className={this.props.classes.buttonStyle}
                  style={{
                    background:
                      this.state.activeIndex === 0
                        ? "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)"
                        : "white",
                    color: this.state.activeIndex === 0 ? "white" : "black",
                  }}
                  onClick={() => this.handleActiveButton(0)}
                >
                  Upcoming
                </Button>
                <Button
                  data-test-id="previous-btn"
                  key={`previous-btn`}
                  className={this.props.classes.buttonStyle}
                  style={{
                    background:
                      this.state.activeIndex === 1
                        ? "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)"
                        : "white",
                    color: this.state.activeIndex === 1 ? "white" : "black",
                  }}
                  onClick={() => this.handleActiveButton(1)}
                >
                  Previous
                </Button>
              </Box>
              <Box
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                gridGap={"1em"}
              >
                <Box position={"relative"}>
                  <SearchIcon
                    style={{
                      position: "absolute",
                      left: "4px",
                      top: "5px",
                      color: "#510081",
                    }}
                  />
                  <input
                    className={this.props.classes.inputSearchStyle}
                    data-test-id="search"
                    placeholder="Search"
                    value={this.state.search}
                    onChange={(event) =>
                      this.handleSearch(event.target.value, "current")
                    }
                  />
                </Box>
                <img
                  src={filterBtn}
                  style={{ width: "20px", cursor: "pointer" }}
                  data-test-id="filter"
                  onClick={this.handleModalFilterCurrent}
                />
                <img
                  src={sortBtn}
                  style={{ width: "20px", cursor: "pointer" }}
                  data-test-id="sort"
                  onClick={this.handleModalSortCurrent}
                />
              </Box>
            </Box>
            <Box>
              <table
                className={this.props.classes.tableStyle}
                cellPadding="0"
                cellSpacing="0"
              >
                <thead>
                  <tr>
                    <th>Subject</th>
                    {this.state.activeIndex === 0 ? (
                      <th>Topic</th>
                    ) : (
                      <th>Class Name</th>
                    )}
                    <th>Class Date</th>
                    {this.state.activeIndex === 0 ? (
                      <th>Class Time</th>
                    ) : (
                      <th>Video Status</th>
                    )}
                    {this.state.activeIndex === 0 ? (
                      <th>Recording</th>
                    ) : (
                      <th>Recorded Class</th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {currentItems.map((data, index) => {
                    const parsedDate = new Date(data.attributes.class_date);
                    const date =
                      this.getOrdinalNumber(parsedDate.getDate()) +
                      " " +
                      parsedDate.toLocaleDateString("en-US", {
                        year: "numeric",
                        month: "long",
                      });
                    const time = parsedDate.toLocaleTimeString([], {
                      hour: "2-digit",
                      minute: "2-digit",
                    });

                    return (
                      <tr key={`${index}-tr-live-class`}>
                        <td>{data.attributes.subject_name}</td>
                        <td>{data.attributes.chapter_name}</td>
                        <td>{date}</td>
                        {this.state.activeIndex === 0 ? (
                          <td>{time}</td>
                        ) : (
                          <td>{data.attributes.class_status}</td>
                        )}
                        {this.state.activeIndex === 0 ? (
                          <td>
                            <a
                              href={data.attributes.class_url}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="view-recording-button"
                            >
                              Attend Class
                            </a>
                          </td>
                        ) : (
                          <td>
                            <button
                              onClick={() =>
                                this.handleGoToRecordedClasses(data.id)
                              }
                              className="view-recording-button"
                            >
                              View Recording
                            </button>
                          </td>
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              <Box
                display="flex"
                flexDirection={"row"}
                justifyContent={"center"}
                alignItems={"center"}
                marginBottom={"5em"}
              >
                <Pagination
                  data-test-id="pagination"
                  count={totalPages}
                  page={this.state.currentPageCurrentTable}
                  onChange={(_: unknown, value: number) =>
                    this.handlePageChangeCurrentTable(value)
                  }
                  className={this.props.classes.muiPaginationStyle}
                />
              </Box>
            </Box>
            <Box>
              <Box className={this.props.classes.bannerStyle}>
                <Typography variant="h6">MATERIAL</Typography>
              </Box>
              <Box
                display={"flex"}
                justifyContent={"flex-end"}
                alignItems={"center"}
                gridGap={"1em"}
                margin={"2.5em 2em 2.5em 0"}
              >
                <Box position={"relative"}>
                  <SearchIcon
                    style={{
                      position: "absolute",
                      left: "4px",
                      top: "5px",
                      color: "#510081",
                    }}
                  />
                  <input
                    className={this.props.classes.inputSearchStyle}
                    data-test-id="search"
                    placeholder="Search"
                    value={this.state.searchMaterial}
                    onChange={(event) =>
                      this.handleSearch(event.target.value, "material")
                    }
                  />
                </Box>
              </Box>
              <Box>
                <table
                  className={this.props.classes.tableStyle2}
                  cellPadding="0"
                  cellSpacing="0"
                >
                  <thead>
                    <tr>
                      <th>Subject</th>
                      <th>Topic</th>
                      <th>Link</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.currentTableMaterial.map((data, index) => {
                      return (
                        <tr key={`${index}-tr-live-class-material`}>
                          <td data-test-id="subject-material">
                            {data.attributes.subject_name}
                          </td>
                          <td>{data.attributes.topic_name}</td>
                          <td>
                            <NavLink
                              to={`/dashboard/classes/live-class/material-note/material/${data.id}`}
                              className={this.props.classes.buttonStyleLink}
                            >
                              Click here
                            </NavLink>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </Box>
            </Box>
            <Box>
              <Box className={this.props.classes.bannerStyle}>
                <Typography variant="h6">
                  FORMULAS/SHORT NOTES FOR REVISION
                </Typography>
              </Box>
              <Box
                display={"flex"}
                justifyContent={"flex-end"}
                alignItems={"center"}
                gridGap={"1em"}
                margin={"2.5em 2em 2.5em 0"}
              >
                <Box position={"relative"}>
                  <SearchIcon
                    style={{
                      position: "absolute",
                      left: "4px",
                      top: "5px",
                      color: "#510081",
                    }}
                  />
                  <input
                    className={this.props.classes.inputSearchStyle}
                    data-test-id="search"
                    placeholder="Search"
                    value={this.state.searchNotes}
                    onChange={(event) =>
                      this.handleSearch(event.target.value, "notes")
                    }
                  />
                </Box>
              </Box>
              <Box>
                <table
                  className={this.props.classes.tableStyle2}
                  cellPadding="0"
                  cellSpacing="0"
                >
                  <thead>
                    <tr>
                      <th>Subject</th>
                      <th>Topic</th>
                      <th>Details</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.currentTableNotes.map((data, index) => {
                      return (
                        <tr key={`${index}-tr-live-class-notes`}>
                          <td data-test-id="subject-note">
                            {data.attributes.subject_name}
                          </td>
                          <td>{data.attributes.chapter_name}</td>
                          <td>
                            <NavLink
                              to={`/dashboard/classes/live-class/material-note/note/${data.id}`}
                              className={this.props.classes.buttonStyleLink}
                            >
                              Click here
                            </NavLink>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyleLiveClass = () =>
  createStyles({
    bannerStyle: {
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      color: "#FFF",
      display: "flex",
      background: "#510081",
      height: "71px",
      "& h5": {
        fontWeight: 700,
      },
    },
    tableStyle: {
      margin: "4em 0",
      textAlign: "left",
      width: "100%",
      tableLayout: "fixed",
      "& thead": {
        background: "#E8E8E8",
        border: "1px solid #E8E8E8",
        "& tr th:last-child": {
          borderTopRightRadius: "0.7em",
        },
        "& tr th:nth-child(1)": {
          paddingLeft: "1.2em",
          borderTopLeftRadius: "0.7em",
        },
        "& tr th": {
          padding: "1.2em 0",
        },
      },
      "& tbody": {
        "& tr:last-child td:nth-child(1)": {
          borderBottomLeftRadius: "0.7em",
        },
        "& tr td": {
          padding: "1.2em 1em 1.2em 0",
        },
        "& tr td:nth-child(1)": {
          paddingLeft: "1.2em",
        },
        "& tr:last-child td:last-child": {
          borderBottomRightRadius: "0.7em",
        },
        "& tr:nth-child(even)": {
          background: "#E8E8E8",
        },
        "& .view-recording-button": {
          background:
            "linear-gradient(90deg,rgba(255, 63, 89, 1) 0%,rgba(254, 98, 86, 1) 100%)",
          color: "#FFF",
          textDecoration: "none",
          padding: "0.6em 2em",
          borderRadius: "0.7em",
          border: "none",
          cursor: "pointer",
          fontSize: "1em",
        },
      },
    },
    tableStyle2: {
      margin: "4em 0",
      textAlign: "center",
      width: "100%",
      "& thead": {
        background: "#FF4552",
        border: "1px solid #C3C3C3",
        color: "white",
        "& tr th": {
          padding: "1.5em 0",
          border: "0.5px solid #CDCDCD",
        },
      },
      "& tbody": {
        "& tr td": {
          textAlign: "center",
          padding: "1.2em 0",
          border: "1px solid #CDCDCD",
        },
      },
    },
    inputSearchStyle: {
      border: "1px solid #510081",
      borderRadius: "1.2em",
      color: "#510081",
      outline: "none",
      padding: "0.5em 0.5em 0.5em 2em",
      "&::placeholder": {
        color: "#510081",
      },
    },
    liveClassContainer: {
      padding: "0 2em",
    },
    modalStyle: {
      position: "absolute" as "absolute",
      top: "50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "0.5em",
      left: "50%",
      width: 500,
      height: "max-content",
      border: "2px solid #832890",
      backgroundColor: "white",
      display: "flex",
      justifyContent: "space-between",
      padding: "2em",
      gap: "1em",
      flexDirection: "column",
      outline: "none",
      textAlign: "left",
      "& input, & label": {
        width: "100%",
        outline: "none" as const,
        border: "1px solid #FF6B55",
        borderRadius: "0.5em",
        padding: "1em",
        color: "#510081",
        "&::placeholder": {
          color: "#510081",
        },
      },
      "& label": {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
      },
      "& label p": {
        fontSize: "1.2em",
        fontWeight: 500,
      },
      "& label input": {
        height: "20px",
        width: "20px",
        borderRadius: "50%",
      },
      "& button": {
        borderRadius: "0.6em",
        border: "1px solid #BE888E",
        textTransform: "none",
        "&:hover": {
          background:
            "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
          color: "white",
        },
        minWidth: "130px",
        width: "max-content",
        color: "#4F1f6d",
        height: "45px",
        fontWeight: 700,
        margin: "0 1em",
      },
    },
    checkbox: {
      appearance: "none",
      border: "none",
      borderRadius: "5em",
      width: "10px",
      height: "10px",
      marginRight: "10px",
      position: "relative",
      transition: "0.3s all ease",
      "&:checked": {
        backgroundColor: "#540083",
        "&:after": {
          content: '""',
          display: "block",
          borderTop: "none",
          width: "13px",
          height: "7px",
          border: "2px solid white",
          borderRight: "none",
          position: "absolute",
          top: "45%",
          left: "50%",
          transform: "translate(-50%, -50%) rotate(-47deg)",
        },
      },
    },
    buttonStyle: {
      border: "1px solid #BE888E",
      borderRadius: "0.6em",
      textTransform: "none" as const,
      "&:hover": {
        background: "#FF5D5B",
        color: "white",
      },
      minWidth: "130px",
      width: "max-content",
      height: "50px",
      color: "#4F1f6d",
    },
    buttonStyleView: {
      border: "none",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "0.6em",
      textTransform: "none" as const,
      display: "flex",
      width: "120px",
      height: "45px",
      color: "white",
      fontSize: "0.9em",
      background:
        "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
    },
    buttonStyleLink: {
      border: "none",
      alignItems: "center",
      width: "140px",
      borderRadius: "0.6em",
      textTransform: "none" as const,
      display: "flex",
      justifyContent: "center",
      height: "40px",
      color: "white",
      margin: "0 auto",
      fontSize: "0.9em",
      background:
        "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
    },
    instructionsStyles: {
      display: "flex",
      minHeight: "350px",
      padding: "1.5em",
      borderRadius: "0.6em",
      border: "2px solid #D8E6EF",
      flexDirection: "column",
      background: "#f5fafe",
      height: "600px",
      overflowY: "hidden",
      width: "55%",
      "&::-webkit-scrollbar": {
        width: "8px",
      },
      "&::-webkit-scrollbar-track": {
        background: "#f1f1f1",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#888",
        borderRadius: "4px",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        background: "#555",
      },
      "&:hover": {
        overflowY: "scroll",
      },
      "& h5": {
        fontWeight: 500,
      },
      "& ul": {
        marginLeft: "3em",
        "& li": {
          fontWeight: 500,
          fontSize: "1.3em",
          listStyle: "disc",
          margin: "1em 0",
        },
      },
    },
    classStyles: {
      display: "flex",
      flexDirection: "column",
      background: "#F8F5FF",
      height: "600px",
      overflowY: "hidden",
      width: "43%",
      minHeight: "350px",
      padding: "1.5em",
      borderRadius: "0.6em",
      border: "2px solid #EDE9F3",
      "& h5": {
        fontWeight: 500,
      },
      "&::-webkit-scrollbar-track": {
        background: "#f1f1f1",
      },
      "&::-webkit-scrollbar-thumb": {
        background: "#888",
        borderRadius: "4px",
      },
      "&::-webkit-scrollbar": {
        width: "8px",
      },
      "&::-webkit-scrollbar-thumb:hover": {
        background: "#555",
      },
      "&:hover": {
        overflowY: "scroll",
      },
      "& ul": {
        marginLeft: "3em",
        "& li": {
          fontWeight: 500,
          fontSize: "1.3em",
          listStyle: "disc",
          margin: "1em 0",
        },
      },
    },
    headerStyle: {
      fontWeight: 700,
    },
    muiPaginationStyle: {
      borderRadius: "4px",
      position: "relative",
      border: "1px solid rgba(255, 58, 87, 0.7)",
      width: "max-content",
      "& li button.Mui-selected": {
        color: "white",
        background:
          "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
        borderRadius: "2px",
        fontWeight: 500,
      },
      "& li": {
        color: "white",
        width: "35px",
        height: "41px",
        background: "white",
        borderRadius: "2px",
      },
      "& li:nth-child(1)": {
        position: "absolute",
        background: "transparent",
        left: -20,
        zIndex: 999,
        top: 10,
        "& button": {
          height: "max-content",
          width: "max-content",
        },
        "& button.Mui-disabled": {
          display: "none",
        },
        "& button svg": {
          background:
            "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
          "& path": {
            fill: "white",
          },
          borderRadius: "50%",
        },
      },

      "& li button:hover": {
        background: "none",
      },
      "& li button": {
        height: "100%",
        alignItems: "center",
        width: "100%",
        background: "none",
        justifyContent: "center",
        margin: 0,
        display: "flex",
        padding: 0,
      },
      "& li div": {
        height: "100%",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        background: "none",
        display: "flex",
      },

      "& li:nth-last-child(1)": {
        background: "transparent",
        position: "absolute",
        top: 10,
        right: -20,
        zIndex: 999,
        "& button svg": {
          background:
            "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
          borderRadius: "50%",
          "& path": {
            fill: "white",
          },
        },
        "& button": {
          height: "max-content",
          width: "max-content",
        },
        "& button.Mui-disabled": {
          display: "none",
        },
      },
    },
  });

export default withStyles(webStyleLiveClass)(LiveClass);
export { LiveClass };
// Customizable Area End
