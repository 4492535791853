import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { WithStyles } from "@material-ui/core";
import storage from "../../../framework/src/StorageProvider.web";
import { IApplicationOpened } from "./ApplicationNewOpeningController.web";
// Customizable Area End

export const configJSON = require("./config");

export interface Props extends WithStyles {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  loading: boolean;
  activeStep: number;
  application: IApplicationOpened | null;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ApplicationDocumentUploadController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  steps: string[] = [
    "Uploaded Documents",
    "Document Verification",
    "Slot Time",
    "Application Processing",
  ];
  getApplicationStatusMessageId = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      loading: false,
      activeStep: 0,
      application: null,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    const response = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId === this.getApplicationStatusMessageId && response) {
      if (response?.data) {
        this.handleSuccessfulApplicationStatusFetch(response.data);
      }
    }
    // Customizable Area End
  }

  // web events
  componentDidMount = async () => {
    this.getApplicationStatus();
  };

  // Customizable Area Start
  isStepFinished = (index: number) => {
    return index < this.state.activeStep;
  };

  getApplicationStatus = async () => {
    this.setState({
      loading: true,
    });

    const getApplicationStatusMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const headers = {
      token: await storage.get("authToken"),
    };

    getApplicationStatusMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_tutorials2/guidance_applications/applications_opened`
    );
    this.getApplicationStatusMessageId = getApplicationStatusMessage.messageId;
    getApplicationStatusMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getApplicationStatusMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(
      getApplicationStatusMessage.id,
      getApplicationStatusMessage
    );
  };
  handleSuccessfulApplicationStatusFetch = (
    fetchedApplication: IApplicationOpened[]
  ) => {
    const searchParams = this.props.navigation.history.location.search;
    const params = new URLSearchParams(searchParams);
    const univId = params.get("id");

    const application =
      fetchedApplication.find((data) => data.id === univId) || null;

    this.setState({
      loading: false,
      application,
    });
  };

  nextBtn = () => {
    if (this.state.activeStep <= 3) {
      this.setState({
        activeStep: this.state.activeStep + 1,
      });
    }
  };

  prevBtn = () => {
    if (this.state.activeStep >= 0) {
      this.setState({
        activeStep: this.state.activeStep - 1,
      });
    }
  };
  // Customizable Area End
}
