import React from "react";

import {
  Box,
  // Customizable Area Start
  withStyles,
  createStyles,
  Button,
  Typography,
  Modal,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import BannerWeb from "../../../components/src/Banner.web";
import UserDocumentWideCard from "../../../components/src/UserDocumentWideCard.web";
import Loader from "../../../components/src/Loader.web";
import { SubmitIcon } from "../../../blocks/CustomisableUserProfiles/src/assets";
import { noDocument } from "./assets";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// Customizable Area End

import DocumentsUploadController, {
  Props,
} from "./DocumentsUploadController.web";

class DocumentsUpload extends DocumentsUploadController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box>
        <Loader loading={this.state.loading} />
        <BannerWeb
          title="Documents status"
          children={
            <Button
              className={this.props.classes.uploadBtn}
              onClick={() => this.handleUploadingModal()}
              data-test-id="upload-btn-initial"
            >
              Upload
            </Button>
          }
        />
        <Box
          display={"flex"}
          padding={"0 2em"}
          gridGap={"2em"}
          width={"100%"}
          position={"relative"}
          marginTop={"3em"}
        >
          {this.documentsFilterButtons.map((btn, index) => {
            return (
              <Button
                onClick={() => this.chooseFilterBtn(btn.btnValue)}
                className={
                  index === this.state.activeFilterIndex
                    ? this.props.classes.filterBtnActive
                    : ""
                }
                data-test-id="filter-btn"
              >
                {btn.btnName}
              </Button>
            );
          })}
          <Box className={this.props.classes.breakLine} />
        </Box>
        <Box
          width={"100%"}
          padding={"2em"}
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"center"}
          gridGap={"2em"}
        >
          <Typography variant="h6" style={{ fontWeight: 500 }}>
            List of uploads approved by MyRank
          </Typography>
          {this.state.userDocuments.map((document) => {
            return <UserDocumentWideCard {...document} />;
          })}
          {this.state.userDocuments.length === 0 && (
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              flexDirection={"column"}
            >
              <img src={noDocument} alt="nodoc" />
              <Typography style={{ fontWeight: 500, fontSize: "1.4em" }}>
                No documents have been uploaded yet
              </Typography>
              <Button
                className={this.props.classes.uploadBtn2}
                onClick={() => this.handleUploadingModal()}
                data-test-id="upload-no-data"
              >
                Upload
              </Button>
            </Box>
          )}
        </Box>
        <Modal
          open={this.state.isUploadingModal}
          onClose={this.handleUploadingModal}
          BackdropProps={{ onClick: undefined }}
        >
          <Box className={this.props.classes.modalStyle}>
            <Box
              width={"100%"}
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <Typography
                variant={"body1"}
                style={{ fontWeight: 500, fontSize: "1.55em" }}
              >
                Upload New Document
              </Typography>
              <Box display="flex" flexDirection="column" width={"100%"} p={2}>
                <Box position={"relative"}>
                  <select
                    onChange={(event) =>
                      this.onChangeSelect(event.target.value)
                    }
                    value={this.state.selectedChoice}
                    data-test-id="select-btn"
                    className={this.props.classes.selectStyle}
                  >
                    <option value="none"> </option>
                    {this.state.userSelectDocument.map((document) => {
                      return (
                        <option value={document.id} key={document.id}>
                          {document.attributes.document_name}
                        </option>
                      );
                    })}
                  </select>
                  <ExpandMoreIcon
                    className={this.props.classes.expandMoreIcon}
                  />
                </Box>
                {this.state.selectedFile && (
                  <Typography variant="body2">
                    Selected file: {(this.state.selectedFile as File).name}
                  </Typography>
                )}
                {!this.state.selectedFile && (
                  <Box
                    display={"flex"}
                    flexDirection={"column"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    border={2}
                    borderColor={this.state.dragActive ? "#f50057" : "#ccc"}
                    borderRadius="5px"
                    style={{ borderStyle: "dashed" }}
                    width={"100%"}
                    minHeight={"max-content"}
                    height={"200px"}
                    p={3}
                    textAlign="center"
                    data-test-id="drag-simulate"
                    onDragOver={this.handleDragOver}
                    onDragLeave={this.handleDragLeave}
                    onDrop={this.handleDrop}
                  >
                    <input
                      type="file"
                      style={{ display: "none" }}
                      onChange={this.handleFileChange}
                      data-test-id="document-upload-input"
                      ref={this.fileInputRef}
                    />
                    <ExitToAppIcon
                      style={{
                        transform: "rotate(-90deg)",
                        color: "#222",
                        fontSize: "3em",
                        marginBottom: "1em",
                      }}
                    />
                    <Button
                      variant="contained"
                      color="secondary"
                      data-test-id="document-upload-browse"
                      className={this.props.classes.browseStyle}
                      onClick={() => this.fileInputRef.current?.click()}
                    >
                      Browse
                    </Button>
                    <Typography variant="caption">Drop a file here</Typography>
                  </Box>
                )}
                {this.state.selectedFile && this.state.selectedChoice && (
                  <Box
                    display={"flex"}
                    justifyContent={"center"}
                    alignItems={"center"}
                  >
                    <Button
                      onClick={() => this.uploadUserDocument()}
                      data-test-id="document-upload-btn"
                      className={this.props.classes.browseStyle}
                    >
                      Upload
                    </Button>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </Modal>
        <Modal
          open={this.state.isDocumentSuccessUploadModal}
          onClose={this.handleDocumentSuccessModal}
        >
          <Box className={this.props.classes.modalStyle}>
            <img src={SubmitIcon} alt="success" />
            <Typography variant={"h6"} style={{ fontWeight: 500 }}>
              Document Submitted Successfully
            </Typography>
            <Button
              onClick={this.handleDocumentSuccessModal}
              data-test-id="close-success-btn"
              className={this.props.classes.buttonStyle}
            >
              Go Back
            </Button>
          </Box>
        </Modal>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export const webStyleDocumentsUpload = () =>
  createStyles({
    uploadBtn: {
      position: "absolute",
      right: "30px",
      color: "white",
      fontWeight: 500,
      background: "#0CA800",
      padding: "0.5em 4em",
      borderRadius: "0.6em",
      "&:hover": {
        background: "#0CA500",
      },
    },
    uploadBtn2: {
      color: "white",
      fontWeight: 500,
      background: "#0CA800",
      padding: "0.5em 4em",
      borderRadius: "0.6em",
      "&:hover": {
        background: "#0CA500",
      },
    },
    browseStyle: {
      width: "200px",
      borderRadius: "5em",
      border: "none",
      color: "white",
      background:
        "linear-gradient(90deg,rgba(255, 63, 89, 1) 0%,rgba(254, 98, 86, 1) 100%)",
    },
    filterBtnActive: {
      color: "white",
      fontWeight: 500,
      background: "#FF4452",
      padding: "0.6em 2em",
      borderRadius: "5em",
      "&:hover": {
        background: "#FF4452",
      },
    },
    breakLine: {
      width: "80%",
      height: "3px",
      color: "gray",
      position: "absolute",
      bottom: "-10px",
      zIndex: 999,
    },
    modalStyle: {
      background: "white",
      minHeight: "max-content",
      height: "450px",
      display: "flex",
      minWidth: "max-content",
      justifyContent: "center",
      width: "40%",
      alignItems: "center",
      flexDirection: "column",
      position: "absolute" as "absolute",
      left: "50%",
      transform: "translate(-50%, -50%)",
      bgcolor: "background.paper",
      padding: "2em 5em",
      border: "none",
      top: "50%",
      gap: "1.5em",
    },
    expandMoreIcon: {
      background:
        "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
      borderRadius: "50%",
      color: "white",
      cursor: "pointer",
      position: "absolute",
      top: "47px",
      right: "30px",
    },
    buttonStyle: {
      borderRadius: "0.6em",
      border: "1px solid #BE888E",
      textTransform: "none" as const,
      "&:hover": {
        color: "white",
        background:
          "linear-gradient(90deg, rgba(255, 63, 89, 1) 0%, rgba(254, 98, 86, 1) 100%)",
      },
      width: "max-content",
      height: "50px",
      color: "#4F1f6d",
      minWidth: "130px",
    },
    selectStyle: {
      outline: 0,
      paddingLeft: "1.5em",
      fontWeight: 500,
      height: "70px",
      fontSize: "1.4em",
      WebkitAppearance: "none",
      border: "none",
      background: "#eee",
      width: "100%",
      margin: "1.3em 0",
      color: "#5C0F89",
    },
  });
export default withStyles(webStyleDocumentsUpload)(DocumentsUpload);
export { DocumentsUpload };
// Customizable Area End
