import React from "react";

import {
  Box,
  // Customizable Area Start
  Typography,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import BannerWeb from "../../../components/src/Banner.web";
import Loader from "../../../components/src/Loader.web";
import StarIcon from "@material-ui/icons/Star";
import PageDefaultExport from "../../../components/src/PageDefaultExport.web";
import { IApplicationOpened } from "./ApplicationNewOpeningController.web";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
// Customizable Area End

import ApplicationDocumentUploadController, {
  Props,
} from "./ApplicationDocumentUploadController.web";

class ApplicationDocumentUpload extends ApplicationDocumentUploadController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  ApplicationRender = (currentApplication: IApplicationOpened) => {
    return (
      <Box
        flexDirection="column"
        display="flex"
        width="600px"
        minHeight="180px"
        className={this.props.classes.applicationBoxStyle}
        height="max-content"
        gridGap={"0.5em"}
        marginTop={"2em"}
        marginBottom={"3em"}
        key={currentApplication.attributes.univ_name}
        padding="1.5em"
      >
        <Typography variant="h6">
          {currentApplication.attributes.univ_name}
        </Typography>
        <Box alignItems={"center"} display={"flex"}>
          <LocationOnOutlinedIcon />
          <Typography variant="body1">
            {currentApplication.attributes.address}
          </Typography>
        </Box>
        <Box alignItems={"center"} display={"flex"}>
          <StarIcon htmlColor={"#FAC530"} fontSize="small" />
          <Typography variant="body1">
            {currentApplication.attributes.rating
              ? currentApplication.attributes.rating
              : 0}{" "}
            / 5
          </Typography>
        </Box>
        <Typography variant="body1">
          {currentApplication.attributes.univ_type} University Estd.{" "}
          {currentApplication.attributes.established}
        </Typography>
      </Box>
    );
  };

  Card = ({
    title,
    ReactIcon,
    onClick,
  }: {
    title: string;
    ReactIcon: React.ElementType;
    onClick?: () => void;
  }) => {
    return (
      <Box
        onClick={() => (onClick ? onClick() : null)}
        data-test-id="card-btn"
        className={this.props.classes.cardContainer}
      >
        <Typography>{title}</Typography>
        <ReactIcon className={this.props.classes.checkIcon} />
      </Box>
    );
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box>
        <Loader loading={this.state.loading} />
        <BannerWeb
          title={`Application for ${this.state.application?.attributes.univ_name}`}
        />
        {this.state.application && (
          <Box
            display="flex"
            marginBottom={"5em"}
            flexDirection={"column"}
            padding={"3em"}
          >
            <Typography variant="h5">Application Status:</Typography>
            <Typography variant="h6">
              You have started applying for{" "}
              {this.state.application?.attributes.univ_name}
            </Typography>
            {this.ApplicationRender(this.state.application)}
            <Box>
              <Typography variant="h6">Required Documents:</Typography>
              <Typography variant="body1">
                These{" "}
                {this.state.application?.attributes.documents_required.length}{" "}
                documents are missing to continue with application
              </Typography>
              <Box
                display={"flex"}
                flexDirection={"column"}
                gridGap={"2em"}
                margin={"3em 0"}
              >
                {this.state.application.attributes.documents_required.map(
                  (data) => {
                    return this.Card({
                      title: data.document_name,
                      ReactIcon: CheckCircleIcon,
                    });
                  }
                )}
              </Box>
            </Box>
            <Box>
              <Typography variant="h6">Submitted Documents:</Typography>
              <Typography variant="body1">
                Myrank has verified and approved below documents
              </Typography>
              <Box
                display={"flex"}
                flexDirection={"column"}
                gridGap={"2em"}
                margin={"3em 0"}
              >
                {this.state.application.attributes.submitted_documents.map(
                  (data) =>
                    this.Card({
                      title: data.document_name,
                      ReactIcon: CheckCircleIcon,
                    })
                )}
              </Box>
            </Box>
          </Box>
        )}
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyles = {
  buttons: {
    border: "1px solid #FF5C5C",
    color: "#FF5C5C",
    padding: "0.7em 2em",
  },
  active: {
    color: "white",
    background: "#FF5C5C",
    "&:hover": {
      background: "#FF5C5C",
    },
  },
  applyNowBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "0.5em",
    color: "white",
    background: "#2271B1",
    width: "120px",
    height: "50px",
    "&:hover": {
      background: "#2271B1",
    },
  },
  applicationBoxStyle: {
    border: "1px solid gray",
    position: "relative",
  },
  cardContainer: {
    width: "100%",
    border: "1px solid red",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "1.5em 2em",
    borderRadius: "0.5em",
    fontWeight: 500,
  },
  checkIcon: {
    color: "green",
    fontSize: "40px",
  },
};

export const ApplicationDocumentUploadPage = PageDefaultExport({
  pageStyles: webStyles,
  component: ApplicationDocumentUpload,
});

export default ApplicationDocumentUpload;
// Customizable Area End
