import React from "react";

import {
  Box,
  // Customizable Area Start
  Stepper,
  Step,
  StepLabel,
  Typography,
  Button,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import BannerWeb from "../../../components/src/Banner.web";
import Loader from "../../../components/src/Loader.web";
import PageDefaultExport from "../../../components/src/PageDefaultExport.web";
// Customizable Area End

import ApplicationStatusController, {
  Props,
} from "./ApplicationStatusController.web";

class ApplicationStatus extends ApplicationStatusController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  CircleEmptyIcon = () => {
    return <Box className="circleIcon" />;
  };

  ListOfUploadedDocuments = () => {
    return (
      <Box>
        <Typography className={this.props.classes.listOfUploadedDocuments}>
          List of uploaded documents
        </Typography>
        <Box component={"ul"} className={this.props.classes.listDocumentsUL}>
          {this.state.application?.attributes.submitted_documents.map(
            (data) => {
              return (
                <Typography component={"li"}>{data.document_name}</Typography>
              );
            }
          )}
        </Box>
      </Box>
    );
  };

  DocumentVerification = () => {
    return (
      <Box>
        <Typography className={this.props.classes.listOfUploadedDocuments}>
          As discussed on call MyRank Team has updated your slot time and date
        </Typography>
        <Typography>Data : 15th May Slot Time : 13:00-14:00</Typography>
        <Typography>Contact Us if you have any doubts</Typography>
        <Typography>Please be available on book slot timing</Typography>
      </Box>
    );
  };

  SlotTime = () => {
    return (
      <Box>
        <Typography className={this.props.classes.listOfUploadedDocuments}>
          We will send you screenshot of every step while applying
        </Typography>
        <Typography>
          You can Click Here to view all accepted/rejected documents
        </Typography>
        <Typography>
          Please download check the document and accept if all details are
          correct or else reject if any details is incorrect
        </Typography>
        <Box>
          <Button>Accept</Button>
          <Button>Reject</Button>
        </Box>
      </Box>
    );
  };

  ApplicationProcessing = () => {
    return (
      <Box>
        <Typography className={this.props.classes.listOfUploadedDocuments}>
          You can View all your accepted and rejected documents
        </Typography>
      </Box>
    );
  };

  DisplayedStep = (step: number) => {
    switch (step) {
      case 0:
        return this.ListOfUploadedDocuments();
      case 1:
        return this.DocumentVerification();
      case 2:
        return this.SlotTime();
      case 3:
        return this.ApplicationProcessing();
      default:
        return null;
    }
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Box>
        <Loader loading={this.state.loading} />
        <BannerWeb title={`Christ University Status`} />
        <Stepper
          alternativeLabel
          activeStep={this.state.activeStep}
          className={this.props.classes.customStepper}
        >
          {this.steps.map((label, index) => {
            return (
              <Step
                key={index}
                completed={this.isStepFinished(index)}
                className={`${index <= this.state.activeStep ? "active" : ""}`}
              >
                <StepLabel StepIconComponent={this.CircleEmptyIcon}>
                  {label}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <Box minHeight={"90vh"} width={"100%"} padding={"2em"}>
          {this.DisplayedStep(this.state.activeStep)}
        </Box>
        <Button onClick={this.nextBtn} data-test-id="next">
          Next
        </Button>
        <Button onClick={this.prevBtn} data-test-id="prev">
          Previous
        </Button>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyles = {
  customStepper: {
    "& .MuiTypography-root": {
      color: "#aaa !important",
      fontWeight: 500,
      fontSize: "1.1em",
    },
    "&.MuiPaper-root": {
      background: "transparent !important",
    },
    "& .MuiStepConnector-completed span": {
      borderColor: "#258E2B",
      borderWidth: "5px",
    },
    "& .Mui-disabled span": {
      borderColor: "#aaa",
      borderWidth: "5px",
    },
    "& .MuiStep-completed + div span": {
      borderColor: "#258E2B",
      borderWidth: "5px",
    },
    "& .MuiStepConnector-active .MuiTypography-root": {
      color: "#aaa !important",
      fontWeight: 500,
      fontSize: "1.1em",
    },
    "& .MuiStep-completed + div span .circleIcon": {
      background: "#258E2B",
    },
    "& .MuiStepConnector-alternativeLabel": {
      left: "calc(-50% + 15px)",
      right: "calc(50% + 12px)",
    },
    "& .circleIcon": {
      background: "#aaa",
      width: "40px",
      height: "40px",
      borderRadius: "50%",
      position: "relative",
      bottom: "7px",
      zIndex: 9999,
    },
    "& .MuiStepConnector-active + span .circleIcon, & .active .circleIcon": {
      background: "#258E2B",
      width: "40px",
      height: "40px",
      borderRadius: "50%",
      position: "relative",
      bottom: "7px",
      zIndex: 9999,
    },
    "& .active *": {
      color: "#258E2B !important",
    },
  },
  listOfUploadedDocuments: {
    fontWeight: 500,
    fontSize: "21px",
  },
  listDocumentsUL: {
    "& li": {
      listStyleType: "square",
      marginLeft: "3em",
      marginTop: "1.2em",
      fontSize: "24px",
    },
  },
};

export const ApplicationStatusPage = PageDefaultExport({
  pageStyles: webStyles,
  component: ApplicationStatus,
});

export default ApplicationStatus;
// Customizable Area End
